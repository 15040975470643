import React from 'react';
import authRoles from '../../auth/authRoles';

import RefundManagements from './count';

const CouponConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/cover-management',
			component: RefundManagements
		},
		{
			exact: true,
			path: '/optir/dashboard/refund-management/detail/:userId',
			component: React.lazy(() => import('./userDetails'))
		}
	]
};

export default CouponConfig;
