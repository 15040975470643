import Axios from 'axios';
import { url } from '../../../endpoint';
import { showMessage } from './message.actions';

export const CHANGE_STATE = 'VIDEO_CHANGE_STATE';

export const getEmails = () => async dispatch => {
	try {
		let res = await Axios.get(`${url}/admin/get-temp-mail`);

		if (res.data.status === 200) {
			dispatch({
				type: 'GET_TEMP_MAIL',
				payload: res.data.data
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const updateEmails = data => async dispatch => {
	try {
		let res = await Axios.post(`${url}/admin/update-temp-mail`, {
			data
		});

		if (res.data.status === 200) {
			dispatch({
				type: 'UPDATE_TEMP_MAIL',
				payload: data
			});
		}
	} catch (error) {
		dispatch(showMessage({ message: error.message }));
	}
};
export const addEmails = data => async dispatch => {
	try {
		let res = await Axios.post(`${url}/admin/create-temp-mail`, {
			email: {
				email: data.email
			}
		});

		if (res.data.status === 200) {
			dispatch({
				type: 'ADD_TEMP_MAIL',
				payload: data
			});
		}
	} catch (error) {
		dispatch(showMessage({ message: error.message }));
	}
};

export const uploadEmail = (data, loader) => async dispatch => {
	try {
		loader(true);
		const formData = new FormData();
		formData.append('file', data.file);

		let res = await Axios.post(`${url}/admin/create-temp-mail`, formData);
		if (res.data.status === 200) {
			dispatch({
				type: 'GET_TEMP_MAIL',
				payload: res.data.data
			});
		}
		loader(false);
	} catch (error) {
		loader(false);
		dispatch(showMessage({ message: error.message }));
	}
};

export const deleteEmails = (oid, cb) => async dispatch => {
	try {
		let res = await Axios.post(`${url}/admin/delete-temp-mail`, {
			oid
		});

		if (res.data.status === 200) {
			dispatch({
				type: 'DELETE_TEMP_MAIL',
				payload: oid
			});
		}
		cb && cb();
	} catch (error) {
		dispatch(showMessage({ message: error.message }));
	}
};
