import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { fileParser, getDefaultFiles, updateDefaultFiles } from 'app/store/actions/fuse/default-file-action';
import {
	getEmails,
	addEmails,
	updateEmails,
	deleteEmails,
	uploadEmail
} from '../../store/actions/fuse/temp-mail-action';
import { uploadJson } from 'app/store/actions/fuse/json-uploader';

export default function useTempMailFile() {
	const dispatch = useDispatch();
	const emails = useSelector(state => state.fuse.tempMailReducer.emails);

	useEffect(() => {
		dispatch(getEmails());
	}, []);

	const [state, setState] = useState([]);
	const [isUpdate, setIsUpdate] = useState(false);
	const [loader, setLoader] = useState(false);
	const [email, setEmail] = useState({});
	const [error, setErr] = useState(false);
	const [renderKey, setRenderKey] = useState(0);
	const [isUpload, setIsUpload] = useState(false);
	const tempInput = useRef(null);
	const addEmail = () => {
		console.log(email);
		if (!email.email) {
			setErr(true);
		} else {
			dispatch(addEmails(email));
			setEmail({});
			setRenderKey(uuid());
		}
	};

	const deleteMail = oid => {
		dispatch(deleteEmails(oid));
	};
	const updateMail = email => {
		// console.log(email);
		// const mails=state.map((item) =>{
		//     if(item.id===email.id){
		//         item.mail=email.mail;
		//     }
		// });
		dispatch(updateEmails(email));
		setIsUpdate(false);
		setEmail({});
		setRenderKey(uuid());
	};

	const updateMailValue = (oid, email) => {
		setEmail({
			oid,
			email
		});
		setIsUpdate(true);
	};

	const uploadJsonHandler = data => {
		dispatch(uploadJson(data, setIsUpload));
	};

	return {
		emails,
		state,
		setState,
		loader,
		email,
		setEmail,
		isUpload,
		setIsUpload,
		addEmail,
		deleteMail,
		updateMailValue,
		isUpdate,
		updateMail,
		renderKey,
		error,
		uploadJsonHandler,
		tempInput
	};
}
