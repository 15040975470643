import authRoles from '../../auth/authRoles';
import JsonUploader from './JsonUploader';

const JsonUploaderConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/json-uploader',
			component: JsonUploader
		}
	]
};

export default JsonUploaderConfig;
