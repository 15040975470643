import { TextFieldFormsy } from '@fuse/core/formsy';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Formsy from 'formsy-react';
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { signInAction } from 'app/store/actions/fuse/auth.actions';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

function JWTLoginTab(props) {
	const [isFormValid, setIsFormValid] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const togglePassword = () => {
		setShowPassword(!showPassword);
	};
	const formRef = useRef(null);

	useEffect(() => {
		if (props.userStatus) {
			console.log(props.userStatus);
			props.cb();
		}
	}, [props.userStatus]);

	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function handleSubmit(model) {
		props.signInAction(model);
	}

	return (
		<div className="w-full">
			<Formsy
				onValidSubmit={handleSubmit}
				onValid={enableButton}
				onInvalid={disableButton}
				ref={formRef}
				className="flex flex-col justify-center w-full"
			>
				<TextFieldFormsy
					className="mb-16"
					type="text"
					name="email"
					label="Email"
					value={email}
					validations={{
						minLength: 4
					}}
					validationErrors={{
						minLength: 'Min character length is 4'
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Icon className="text-20" color="action">
									email
								</Icon>
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>

				<TextFieldFormsy
					className="mb-16"
					type={showPassword ? 'text' : 'password'}
					name="password"
					label="Password"
					value={password}
					validations={{
						minLength: 4
					}}
					validationErrors={{
						minLength: 'Min character length is 4'
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment onClick={togglePassword} position="end">
								{showPassword ? (
									<MdOutlineVisibilityOff cursor="pointer" size={20} />
								) : (
									<MdOutlineVisibility cursor="pointer" size={20} />
								)}
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>
				{props.isLoaderTrue ? (
					<Button
						variant="contained"
						color="primary"
						className="w-full mx-auto mt-16 normal-case"
						aria-label="LOG IN"
						disabled={true}
						value="legacy"
					>
						<Loader type="Bars" color="#182D3E" height={24} width={20} />
					</Button>
				) : (
					<Button
						type="submit"
						variant="contained"
						color="primary"
						className="w-full mx-auto mt-16 normal-case"
						aria-label="LOG IN"
						disabled={!isFormValid}
						value="legacy"
					>
						Login
					</Button>
				)}
			</Formsy>
		</div>
	);
}

const mapStateToProps = store => {
	return {
		userStatus: store.fuse.loginReducer.userStatus,
		isLoaderTrue: store.fuse.loginReducer.loader
	};
};

export default connect(mapStateToProps, { signInAction })(JWTLoginTab);
