import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialouge from './add-letter-model';

import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toastr from 'toastr';
import ShowCoverLetter from './show-cover-letter';
import ReactHtmlParser from 'react-html-parser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { useHistory } from 'react-router-dom';
import { styles } from './style';
import TableLoader from './tableLoader';
import moment from 'moment';
var n = 0;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
	root: {
		width: '100%'
	},
	container: {
		maxHeight: 440
	}
});

function CoverLetterTable(props) {
	const classes = useStyles();
	const [isSelected, setSelected] = React.useState('');
	const [page, setPage] = React.useState(0);
	// const [copied, setCopies] = React.useState(false);
	let history = useHistory();
	const [pageSelected, setPageSelected] = React.useState(null);

	const [open, setOpen] = React.useState(false);
	const [oid, setOid] = React.useState('');

	const handleClickOpen = id => {
		setOpen(true);
		setOid(id);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// if (copied) {
	//   toastr.success("cover letter copied!");
	// }
	const _getCoverData = page => {
		props.GetCoverLetters(page);
	};

	React.useEffect(() => {
		n += 1;
		if (n === 1) {
			_getCoverData(1);
		}
		// props.GetCoverAdminLetters()
	}, []);
	// console.log("fileScanReducer", props.fileScanReducer, props.templateReducer);

	const columns = [
		{ id: 'title', label: 'Title' },
		{ id: 'decription', label: 'Description' },
		{ id: 'tags', label: 'Tags' },
		{ id: 'default_id', label: 'Default' },
		{ id: 'createdAt', label: 'Created At' },
		{ id: 'actions', label: 'Actions' }
	];

	function createData(title, decription, tags, default_id, createdAt, actions) {
		return {
			title,
			decription,
			createdAt,
			actions,
			tags,
			default_id
		};
	}

	const onChangeR = i => {
		if (props.cover_id != i) {
			props.updateMasterResuma({ oid: i, type: 'cover' });
		}
	};

	const rows = props.data.map((item, i) =>
		createData(
			item.title,
			ReactHtmlParser(item.description.toString().slice(0, 100) + '...'),
			item.tags ? item.tags : 'None',

			'Resuma Default',
			moment(item.createdAt).format('MMM, DD YYYY'),
			<span style={{ display: 'flex', flexDirection: 'row' }}>
				<ShowCoverLetter
					detail={item}
					AddCoverLetter={props.AddCoverLetter}
					getCoverData={props.GetCoverLetters}
					UpdateCoverLetter={props.UpdateCoverLetter}
				/>

				<CopyToClipboard
					text={item.title + ' ' + ' ' + ' ' + ' ' + ' ' + item.description}
					onCopy={() => toastr.success('cover letter copied!')}
				>
					<FileCopyIcon className="hovdel" style={styles.fileCopyIcon} />
				</CopyToClipboard>
				{/* 
				<RateReviewIcon
					onClick={() => history.push('/cover-letter-review')}
					className="hovdel"
					style={styles.fileCopyIcon}
				/> */}
				<DeleteOutlineIcon
					onClick={() => handleClickOpen(item._id)}
					className="hovdel"
					style={styles.delIcon}
				/>
			</span>
		)
	);

	if (props.datatatus) {
		// return <div className="loading"></div>;
		return <TableLoader />;
	} else
		return (
			<div>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">{'are you sure! want to delete?'}</DialogTitle>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							cancel
						</Button>
						<Button
							onClick={() => {
								props.DeleteCoverLatter({ oid: oid });
								setOpen(false);
							}}
							color="secondary"
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				<div style={{ marginBottom: props.data.length ? 80 : 31 }}>
					<div style={{ ...styles.flex, ...styles.spaceBetween }}>
						<h3 style={styles.h3}>Cover Letters</h3>
						<Dialouge
							AddCoverLetter={props.AddCoverLetter}
							fileParser={props.fileParser}
							getCoverData={props.GetCoverLetters}
							resumeDescription={props.resumeDescription}
						/>
					</div>
					<Paper className={classes.root}>
						<TableContainer className={classes.container}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map(column => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								{props.data.length && rows.length ? (
									<TableBody>
										{rows.map(row => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
													{columns.map(column => {
														const value = row[column.id];
														return (
															<TableCell
																key={column.id}
																align={column.align}
																style={{ borderBottom: 'none' }}
															>
																{column.format && typeof value === 'number'
																	? column.format(value)
																	: value}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
									</TableBody>
								) : (
									<TableRow>
										<TableCell colSpan={4}>
											<div className="resume_main_container" style={styles.tableCell}>
												No Data Found!
											</div>
										</TableCell>
									</TableRow>
								)}
							</Table>
						</TableContainer>
					</Paper>
				</div>
			</div>
		);
}
export default CoverLetterTable;
