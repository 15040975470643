import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { useHistory, withRouter } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import UserTableHead from './userTableHead';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import { addSkillsAction } from '../../store/actions/fuse/usersActon';
import UserTableHead from './userTableHead';

// import { useDispatch } from 'react-redux';

const default_counts_managements = {
	certs: 10,
	core_skills: 60,
	education: 20,
	mgmt_level: 0,
	min_years_exp: 0,
	oid: '5fb53dede6114cbd72a94fa4',
	soft_skills: 5,
	title: 5
};

function UsersTable({ data = [] }, props) {
	const history = useHistory();
	console.log('data', data);
	const [order, setOrder] = useState({
		direction: 'asc',
		id: null
	});

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		console.log(order.id);

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	const handleClick = (item, index) => {
		history.push(`/optir/dashboard/refund-management/detail/${item.payment_intent}`, item);
	};

	return (
		<div className="w-full flex flex-col">
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<Table className="min-w-xl" aria-labelledby="tableTitle">
					<UserTableHead
						// numSelected={selected.length}
						order={order}
						onRequestSort={handleRequestSort}
						rowCount={data.length}
					/>

					<TableBody>
						{_.orderBy(
							data,
							[
								o => {
									switch (order.id) {
										case 'id': {
											return parseInt(o.id, 10);
										}
										case 'amount': {
											return o.amount;
										}
										case 'status': {
											return o.status;
										}
										case 'currency': {
											return o.currency;
										}
										// case 'email': {
										// 	return o.email;
										// }
										// case 'phone': {
										// 	return o.phone;
										// }
										// case 'gender': {
										// 	return o.gender;
										// }
										// case 'birthDate': {
										// 	return o.birthDate;
										// }
										// case 'country': {
										// 	return o.country;
										// }
										// case 'city': {
										// 	return o.city;
										// }
										// case 'education': {
										// 	return o.education;
										// }
										case 'created': {
											return o.created;
										}
										// case 'status': {
										// 	return o.status;
										// }
									}
								}
							],
							[order.direction]
						).map((items, index) => {
							return (
								<TableRow
									onClick={() => handleClick(items, index)}
									hover
									key={index}
									className="h-64 cursor-pointer"
								>
									<TableCell component="th" scope="row">
										{items.id}
									</TableCell>
									<TableCell component="th" scope="row">
										${items.amount / 100}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.status}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.currency}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.created}
									</TableCell>
									{/* <TableCell component="th" scope="row">
										{items.phone}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.gender}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.birthDate}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.country}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.city}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.education}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.registeredAt}
									</TableCell>
									<TableCell component="th" scope="row">
										<div style={items.status === 'active' ? activeStatus : blockStatus}>
											{items.status}
										</div>
									</TableCell> */}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</FuseScrollbars>

			{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
				<Pagination
					count={Math.ceil(props.totalLenth / rowsPerPage)}
					variant="outlined"
					color="secondary"
					onChange={handleChangePage}
				/>
			</div> */}
		</div>
	);
	// : (
	// 	<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
	// 		<img
	// 			src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
	// 			alt="user_image"
	// 			style={{ width: '40%' }}
	// 		/>
	// 	</div>
	// );
}

export default withRouter(UsersTable);
