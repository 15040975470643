import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import React from 'react';
import reducer from '../../store/reducers';
import UsersHeader from './UsersHeader';
import UsersTable from './usersTable';

function UsersManagement() {
	const [searchText, setSearchText] = React.useState('');
	return (
		<FusePageCarded
			classes={{
				content: 'flex',
				header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
			}}
			header={<UsersHeader sText={v => setSearchText(v)} />}
			content={<UsersTable sendSText={searchText} />}
			innerScroll
		/>
	);
}

export default withReducer('eCommerceApp', reducer)(UsersManagement);
