import React from 'react';
import authRoles from '../../auth/authRoles';

import SubscriptionManagement from './subscription-management';

const SubscriptionAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/subscription-management',
			component: SubscriptionManagement
		},
		{
			exact: true,
			path: '/optir/dashboard/subscription-management/detail/:userId',
			component: React.lazy(() => import('./user-detail/userDetail'))
		}
	]
};

export default SubscriptionAppConfig;
