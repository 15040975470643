import UserManagementService from 'app/services/userManagementService';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';

export const getNotifications = () => async dispatch => {
	// console.log('came here', page, rowsPerPage);
	try {
		dispatch({
			type: 'GET_NOTIFICATION_REQUEST'
		});
		const res = await UserManagementService.getNotification();
		if (res) {
			dispatch({
				type: GET_NOTIFICATION,
				payload: res.data
			});
		} else {
			dispatch({
				type: 'GET_NOTIFICATION_FAILED'
			});
		}
	} catch (err) {
		dispatch({
			type: 'GET_NOTIFICATION_FAILED'
		});
		console.log('err', err);
	}
};

export const addNotification = data => async dispatch => {
	try {
		const res = await UserManagementService.addNotification(data);
		if (res) {
			console.log('res', res);
			dispatch({
				type: 'ADD_NOTIFICATION_SUCCESS'
			});
		} else {
			dispatch({
				type: 'ADD_NOTIFICATION_FAILED'
			});
		}
	} catch (err) {
		dispatch({
			type: 'ADD_NOTIFICATION_FAILED'
		});
		console.log('err', err);
	}
};

export const updateNotification = data => async dispatch => {
	const res = await UserManagementService.updateNotification(data);
	if (res) {
		dispatch({
			type: 'UPDATE_NOTIFICATION_SUCCESS'
		});
	} else {
		dispatch({
			type: 'UPDATE_NOTIFICATION_FAILED'
		});
	}
};

export const deleteNotification = data => async dispatch => {
	const res = await UserManagementService.deleteNotification(data);
	if (res) {
		dispatch({
			type: 'DELETE_NOTIFICATION_SUCCESS'
		});
	} else {
		dispatch({
			type: 'DELETE_NOTIFICATION_FAILED'
		});
	}
};
