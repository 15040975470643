export const styles = {
	flex: {
		display: 'flex'
	},
	justifyContentCenter: {
		justifyContent: 'center'
	},
	justifyContentEnd: {
		justifyContent: 'flex-end'
	},
	spaceBetween: {
		justifyContent: 'space-between'
	},
	alignItemsCenter: {
		alignItems: 'center'
	},
	width_100_percent: {
		width: '100%'
	},
	width_80_percent: {
		width: '80%'
	},
	fontWeight_400: {
		fontWeight: 400
	},
	fontWeight_bold: {
		fontWeight: 'bold'
	},
	fontSize_1_rem: {
		fontSize: '1rem'
	},
	mt_5: {
		marginTop: 5
	},
	mt_30: { marginTop: 30 },
	mt_20: {
		marginTop: 20
	},
	mt_10: {
		marginTop: 10
	},
	mb_5: {
		marginBottom: 5
	},
	mb_10: {
		marginBottom: 10
	},
	mb_90: {
		marginBottom: 90
	},
	textAlignCenter: {
		textAlign: 'center'
	},
	btnModel: {
		height: '82%',
		color: 'white',
		backgroundColor: '#4267B2',
		borderRadius: 0
	},
	imgUploadModel: {
		fontWeight: '600',
		color: 'white',
		cursor: 'pointer',
		fontSize: '16px',
		margin: 0,
		paddingLeft: 8,
		paddingRight: 8
	},
	inputModel: {
		border: '1px solid #F1F1F1',
		height: '300px',
		marginTop: 15
	},
	fileCopyIcon: {
		cursor: 'pointer',
		fontSize: 24,
		marginLeft: 6,
		marginRight: 6
	},
	delIcon: {
		cursor: 'pointer',
		fontSize: 25,
		color: 'red',
	},
	h3: {
		fontSize: '1.3rem',
		display: 'inline-block',
		fontWeight: 'bold',
		fontFamily: 'inherit',
		letterSpacing: '3px',
		color: '#4A4A4A'
	},
	tableCell: {
		width: '100%',
		textAlign: 'center',
		paddingTop: 40,
		paddingBottom: 40
	},
	warningColor: {
		backgroundColor: '#FFCC00',
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: 40,
		color: 'white'
	},
	dangerColor: {
		backgroundColor: '#F45B40',
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: 40,
		color: 'white'
	},
	rowSpan: {
		flexDirection: 'row',
		alignItems: 'baseline',
		justifyContent: 'space-around'
	},
	positinRelative: {
		position: 'relative'
	},
	polar: {
		width: '100%',
		height: '400px'
	},
	summaryTypography: {
		fontWeight: 400,
		textAlign: 'center',
		marginTop: 20
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	zIndex: {
		zIndex: 1
	},
	notesModel: {
		position: 'relative'
		// bottom: 5,
	},
	notesModelIcon: {
		cursor: 'pointer',
		fontSize: 25
		// marginTop: -10
	}
};
