import CouponService from 'app/services/couponServices';
import { showMessage } from 'app/store/actions/fuse';

export const REQUEST_FOR_SAVE = 'REQUEST_FOR_SAVE';
export const SAVE_COUPON_SUCCESSFULLY = 'SAVE_COUPON_SUCCESSFULLY';
export const SAVE_COUPON_FAILURE = 'SAVE_COUPON_FAILURE';
export const FETCHED_COUPON = 'FETCHED_COUPON';

export const saveCouponAction = (data, cb) => async dispatch => {
	try {
		dispatch({
			type: REQUEST_FOR_SAVE
		});
		const res = await CouponService.saveCoupon(data);
		console.log('data is here', res);
		if (res.data.status === 200) {
			dispatch(showMessage({ message: 'Saved successfully' }));
			dispatch({
				type: SAVE_COUPON_SUCCESSFULLY
			});
			cb();
		} else {
			dispatch(showMessage({ message: res.data.message }));
			dispatch({
				type: SAVE_COUPON_FAILURE
			});
		}
	} catch (err) {
		dispatch(showMessage({ message: err.message }));
		dispatch({
			type: SAVE_COUPON_FAILURE
		});
	}
};

export const getCouponAction = data => async dispatch => {
	try {
		dispatch({
			type: 'GET_COUPON_STATR'
		});
		const res = await CouponService.getCoupon();
		if (res.data.status === 200) {
			dispatch({
				type: FETCHED_COUPON,
				payload: res.data.data
			});
		}
	} catch (err) {
		dispatch({
			type: 'GET_COUPON_FAILED'
		});
		dispatch(showMessage({ message: err.message }));
	}
};

export const delCouponAction = data => async dispatch => {
	try {
		const res = await CouponService.delCoupon(data);
		if (res.data.status === 200) {
			dispatch(showMessage({ message: 'delete successfully' }));
			const response = await CouponService.getCoupon();
			if (response.data.status === 200) {
				dispatch({
					type: FETCHED_COUPON,
					payload: response.data.data
				});
			}
		}
	} catch (err) {
		dispatch(showMessage({ message: err.message }));
	}
};
