import React from 'react';
import authRoles from '../../auth/authRoles';
import AdminManagement from './admin-management';

const AddAdminConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/admin-management',
			component: AdminManagement
		},
		{
			exact: true,
			path: '/optir/dashboard/admin-management/detail/:adminNumber/:adminId',
			component: React.lazy(() => import('./admin-details/userDetail'))
		}
	]
};

export default AddAdminConfig;
