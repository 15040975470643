import React, { memo } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Button, CircularProgress, Divider, Icon, InputBase, Paper, Typography } from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { FolderOpen } from '@material-ui/icons';
import { styles } from './styles';
import useJsonUploader from './useJsonUploader';

const TempMailManagement = () => {
	const { isUpload, uploadJsonHandler, tempInput } = useJsonUploader();

	return (
		<FusePageSimple
			header={
				<div className="flex flex-1 items-center p-24">
					<div className="flex items-center styleButton">
						<FuseAnimate animation="transition.expandIn" delay={300}>
							<Icon className="text-32">cloud_upload</Icon>
						</FuseAnimate>
						<FuseAnimate animation="transition.slideLeftIn" delay={300}>
							<Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
								Json Uploader
							</Typography>
						</FuseAnimate>
					</div>
				</div>
			}
			content={
				<div style={styles.contentContainer}>
					<FuseAnimate animation="transition.slideLeftIn" delay={300}>
						<div style={styles.addContainer}>
							<div style={styles.ButtonDiv}>
								<Button
									disabled={isUpload}
									variant="contained"
									color="primary"
									style={styles.button}
									onClick={() => {
										document.getElementById('tempMail').click();
									}}
								>
									<Icon className="text-32">cloud_upload</Icon>
									&nbsp;
									{isUpload ? 'Uploading...' : 'Upload'}
								</Button>
								<input
									type="file"
									name="tempMail"
									ref={tempInput}
									id="tempMail"
									style={{ display: 'none' }}
									onChange={e => {
										const data = e.target.files[0];
										uploadJsonHandler(data);
									}}
								/>
							</div>
						</div>
					</FuseAnimate>
					<div>
						<div style={styles.heading}>
							<Typography variant="h6" style={styles.headingText}>
								JSON uploaded file
							</Typography>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default memo(TempMailManagement);
