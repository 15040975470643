import {
	CLEAR_VERSION_DATA,
	DELETE_VERSION_LOADER,
	addVersions,
	deleteVersions
} from 'app/store/actions/fuse/version.action';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

const useVersion = () => {
	const [addVersionLoader, setAddVersionLoader] = useState(false);
	const [addVersionModal, setAddVersionModal] = useState(false);
	const [deviceName, setDeviceName] = useState('');
	const [version, setVersion] = useState('');
	const [loader, setLoader] = useState(false);
	const [versionDesc, setVersionDesc] = useState('');
	const { deleteVersionData, deleteLoader } = useSelector(({ fuse }) => fuse.versionReducer);
	const dispatch = useDispatch();
	const toggleAddModal = () => {
		setAddVersionModal(!addVersionModal);
	};

	const addVersionHandler = () => {
		const data = {
			version,
			description: versionDesc,
			isCurrent: true
		};
		setAddVersionLoader(true);
		dispatch(
			addVersions(deviceName, data, () => {
				setAddVersionLoader(false);
				setAddVersionModal(false);
			})
		);

		setDeviceName('');
		setVersion('');
		setVersionDesc('');
	};
	const deleteVersionHandler = () => {
		dispatch(deleteVersions());
	};

	const platforms = [
		{
			option: 'Web',
			value: 'web'
		},
		{
			option: 'Mobile',
			value: 'mobile'
		}
	];

	const deleteModalActions = [
		{
			title: 'Cancel',
			handler: () => {
				dispatch({ type: DELETE_VERSION_LOADER, payload: false });
				dispatch({ type: CLEAR_VERSION_DATA });
			}
		},
		{
			title: 'Delete',
			handler: deleteVersionHandler,
			loading: deleteLoader,
			style: {
				backgroundColor: 'red',
				color: '#fff'
			}
		}
	];

	const addModalActions = [
		{
			title: 'Cancel',
			handler: toggleAddModal,
			style: {
				backgroundColor: 'red',
				color: '#fff'
			}
		},
		{
			title: 'Add',
			handler: addVersionHandler,
			loading: addVersionLoader
		}
	];

	return {
		addVersionLoader,
		setAddVersionLoader,
		addVersionModal,
		setAddVersionModal,
		deviceName,
		setDeviceName,
		version,
		setVersion,
		dispatch,
		toggleAddModal,
		addVersionHandler,
		platforms,
		deleteVersionData,
		deleteLoader,
		deleteVersionHandler,
		deleteModalActions,
		addModalActions,
		loader,
		setLoader,
		versionDesc,
		setVersionDesc
	};
};

export default useVersion;
