import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Pagination } from '@material-ui/lab';
import FuseSplashScreen from '@fuse/core/fuseSplash2';
import MoreVert from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';
import { getAdminAction, deleteAdminAction } from '../../store/actions/fuse/usersActon';
import AdminTableHead from './AdminTableHead';

const activeStatus = {
	color: 'white',
	backgroundColor: '#4caf50',
	padding: '.4rem',
	borderRadius: '.4rem',
	textAlign: 'center'
};

const blockStatus = {
	color: 'white',
	backgroundColor: '#d32f2f',
	padding: '.4rem',
	borderRadius: '.4rem',
	textAlign: 'center'
};

function AdminTable(props) {
	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	// const [page, setPage] = useState(1);
	const [defaultPage, setDefaultPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [order, setOrder] = useState({
		direction: 'asc',
		id: null
	});
	useEffect(() => {
		props.getAdminAction(1, rowsPerPage);
	}, []);

	useEffect(() => {
		setTimeout(() => <FuseSplashScreen />, 2000);
		setData(props.getAdmins);
		console.log(props.getAdmins);
	}, [props.getAdmins]);

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		console.log(order.id);

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	function handleClick(item, index) {
		// props.history.push(`/optir/dashboard/users/detail`, item);
		props.history.push(`/optir/dashboard/admin-management/detail/u${index + 1}/${item.oid}`, item);
	}

	function handleChangePage(event, value) {
		props.getAdminAction(value, rowsPerPage);
		setDefaultPage(value);
	}
	const column = ['id', 'email', 'role'];
	let filteredData = data;
	const searchStringForData = props.sendSText.trim().toLowerCase();
	if (searchStringForData.length > 0) {
		filteredData = filteredData?.filter(item => {
			// return item.firstName.toLowerCase().match(searchStringForData);
			return Object.keys(item).some(key => {
				return column.includes(key)
					? item[key]
						.toString()
						.toLowerCase()
						.includes(searchStringForData)
					: console.log('key', key);
			});
		});
	}

	return props?.getAdmins && props?.getAdmins.length ? (
		<div className="w-full flex flex-col">
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<Table className="min-w-xl" aria-labelledby="tableTitle">
					<AdminTableHead
						numSelected={selected.length}
						order={order}
						onRequestSort={handleRequestSort}
						rowCount={filteredData.length}
					/>

					<TableBody>
						{_.orderBy(
							filteredData,
							[
								o => {
									// eslint-disable-next-line default-case
									switch (order.id) {
										case 'id': {
											return parseInt(o.id, 10);
										}
										case 'Email': {
											return o.email;
										}
										case 'Role': {
											return o.role;
										}
									}
								}
							],
							[order.direction]
						)?.map((items, index) => {
							return (
								<TableRow
									onClick={event => handleClick(items, index)}
									hover
									key={index}
									className="h-64 cursor-pointer"
								>
									<TableCell component="th" scope="row">
										{items?.email}
									</TableCell>
									<TableCell component="th" scope="row">
										{items?.role}
									</TableCell>
									<TableCell component="th" scope="row">
										{items?.permissions?.map(item => {
											return (<li>{item?.pageName}</li>)
										})}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.registeredAt}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</FuseScrollbars>

			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
				<Pagination
					defaultPage={defaultPage}
					count={Math.ceil(props.totalLenth / rowsPerPage)}
					variant="outlined"
					color="secondary"
					onChange={handleChangePage}
				/>
			</div>
		</div>
	) : (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
			<img
				src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
				alt="user_image"
				style={{ width: '40%' }}
			/>
		</div>
	);
}

const mapStateToProps = store => {
	return {
		getAdmins: store.fuse.usersReducer.adminData,
		// getUsers: store.fuse.usersReducer.data,
		totalLenth: store.fuse.usersReducer.totalUsersLength,
		getAdminStatus: store.fuse.usersReducer.getAdminStatus
	};
};

export default connect(mapStateToProps, { getAdminAction, deleteAdminAction })(withRouter(AdminTable));
