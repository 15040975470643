import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import UserTableHead from './userTableHead';
import { connect } from 'react-redux';
import { getSkillsAction } from '../../store/actions/fuse/usersActon';
import { Pagination } from '@material-ui/lab';

const activeStatus={
	color: "white",
	backgroundColor: "#4caf50",
	padding: ".4rem",
	borderRadius:".4rem",
	textAlign:"center"
}

const blockStatus={
	color: "white",
	backgroundColor: "#d32f2f",
	padding: ".4rem",
	borderRadius:".4rem",
	textAlign:"center"
}

function UsersTable(props) {
	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	const [id, setid] = useState('');
	// const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [order, setOrder] = useState({
		direction: 'asc',
		id: null
	});

	// React.useEffect(()=>{
	// 	props.getSkillsAction();
	// },[])

	// useEffect(() => {
	// 	props.getUsersAction(1, rowsPerPage);
	// }, []);

	useEffect(() => {
		if(props.softSkills){
			setData(props.softSkills.data ? props.softSkills.data : []);
			setid(props.softSkills.oid ? props.softSkills.oid : '');
			props.setOid(props.softSkills.oid ? props.softSkills.oid : '');
		}
	}, [props.softSkills]);

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		console.log(order.id);

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	// function handleClick(item,index) {
	// 	props.history.push(`/optir/dashboard/users/detail/u${index + 1}/${item.id}`, item);
	// }

	// function handleChangePage(event, value) {
	// 	props.getUsersAction(value, rowsPerPage);
	// }

	// var filteredData = data;
	// let searchStringForData = props.sendSText.trim().toLowerCase();
	// if (searchStringForData.length > 0) {
	// 	filteredData = filteredData.filter(function(item) {
	// 		return item.firstName.toLowerCase().match(searchStringForData);
	// 	});
	// }
	console.log(props.avoidedWords, data);
	return (
		<div className="w-full flex flex-col">
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<Table aria-labelledby="tableTitle">
					<UserTableHead
						numSelected={selected.length}
						order={order}
						onRequestSort={handleRequestSort}
						rowCount={data.length}
					/>

					<TableBody>
						{_.orderBy(
							data,
							[
								o => {
									switch (order.id) {
										case 'id': {
											return parseInt(id, 10);
										}
										case 'skills': {
											return 'skills';
										}
									}
								}
							],
							[order.direction]
						).map((items, index) => {
							return (
								<>
									<TableRow hover className="h-64 cursor-pointer">
										<TableCell component="th" scope="row">
											{items}
										</TableCell>
									</TableRow>
								</>
							);
						})}
					</TableBody>
				</Table>
			</FuseScrollbars>

			{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
				<Pagination
					count={Math.ceil(props.totalLenth / rowsPerPage)}
					variant="outlined"
					color="secondary"
					onChange={handleChangePage}
				/>
			</div> */}
		</div>
	);
}

const mapStateToProps = store => {
	return {
		softSkills: store.fuse.usersReducer.softSkills,
	};
};

export default connect(mapStateToProps, {getSkillsAction})(withRouter(UsersTable));
