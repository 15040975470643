import React, { memo, useEffect } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Icon, Typography, Button } from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';
import InputButton from './inputButton';
import { getEmailTemplates, updateEmailTemplate } from 'app/store/actions/fuse/email-template-action';
import { useDispatch, useSelector } from 'react-redux';

const EmailTemplates = () => {
	const { fieldTemplates } = useSelector(state => state.fuse.emailTemplateReducer);
	const [isLoading, setIsLoading] = React.useState(false);
	const [changedTemplate, setChangedTemplete] = React.useState({});
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getEmailTemplates());
	}, []);

	useEffect(() => {
		setChangedTemplete(fieldTemplates);
	}, [fieldTemplates]);

	const cb = () => {
		setIsLoading(false);
	};

	const updateHandler = () => {
		setIsLoading(true);
		dispatch(updateEmailTemplate(changedTemplate, cb));
	};

	const changeHandler = (key, value) => {
		let obj = {
			...fieldTemplates,
			[key]: value
		};
		setChangedTemplete(obj);
	};

	return (
		<FusePageSimple
			header={
				<div className="flex flex-1 items-center justify-between p-24">
					<div className="flex items-center styleButton">
						<FuseAnimate animation="transition.expandIn" delay={300}>
							<Icon className="text-32">all_inbox</Icon>
						</FuseAnimate>
						<FuseAnimate animation="transition.slideLeftIn" delay={300}>
							<Typography variant="h6" className="mx-12 hidden sm:flex">
								Email Templates
							</Typography>
						</FuseAnimate>
						<FuseAnimate>
							{isLoading ? (
								<Button disabled variant="contained" color="secondary">
									Updating...
								</Button>
							) : (
								<Button onClick={updateHandler} variant="contained" color="secondary">
									Update
								</Button>
							)}
						</FuseAnimate>
					</div>
				</div>
			}
			content={
				<div
					style={{
						marginTop: '30px',
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-around',
						alignItems: 'center'
					}}
				>
					{Object.keys(changedTemplate).map((key, index) => (
						<div
							key={key + index}
							style={{
								width: '60%',
								height: '100px',
								marginTop: '10px',
								borderRadius: '7px'
							}}
						>
							<InputButton label={key} value={changedTemplate[key]} onChange={changeHandler} />
						</div>
					))}
				</div>
			}
		/>
	);
};

export default memo(EmailTemplates);
