import React, { Component } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

export default class From extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDate: ''
		};
	}

	handleDateChange = date => {
		this.setState({ selectedDate: date });
	};

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.validFrom) {
			return {
				selectedDate: nextProps.validFrom
			};
		}
	}

	render() {
		const { selectedDate } = this.state;
		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<div key="datetime_default" className="picker">
					<DateTimePicker
						fullWidth
						value={selectedDate}
						showTabs={false}
						disabled={this.props.disabled}
						onChange={this.handleDateChange}
						leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
						rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
					/>
				</div>
			</MuiPickersUtilsProvider>
		);
	}
}
