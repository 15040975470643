import FusePageSimple from '@fuse/core/FusePageSimple';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './css.css';
import { addAvoidWordsAction, fileParser } from 'app/store/actions/fuse/usersActon';
import { ExcelRenderer } from 'react-excel-renderer';
import { getAvoidWords } from '../../store/actions/fuse/usersActon';
import { connect } from 'react-redux';
import Table from './usersTable';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import FuseSplashScreen from '@fuse/core/fuseSplash2';

function ChangelogDoc(props) {
	const [open, setOpen] = React.useState(false);
	const [tags, settags] = React.useState([]);
	const [oid, setOid] = React.useState('');

	const handleClickOpen = () => {
		setOpen(true);
		settags(props.avoidedWords.data?.length ? props.avoidedWords.data : []);
	};
	const callBack = () => setOpen(!open);
	const handleClose = () => {
		setOpen(false);
	};

	const submitTags = () => {
		if (tags.length) {
			let data = {
				oid: oid,
				avoids: tags
			};
			props.addAvoidWordsAction(data, callBack);
		} else {
		}
	};

	React.useEffect(() => {
		props.getAvoidWords();
	}, []);

	const handleFile = e => {
		let fileObj = e.target.files[0];

		ExcelRenderer(fileObj, (err, resp) => {
			if (err) {
				console.log(err);
			} else {
				let getTags = [];
				resp.rows.map(item => {
					getTags.push(item);
				});
				settags(getTags);
			}
		});
	};

	return (
		<>
			<Dialog className="couponDialogBox" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Add Words to Avoid</span>
						<span class="image-upload">
							<label
								for="file-input"
								style={{
									fontWeight: '600',
									color: 'white',
									cursor: 'pointer',
									fontSize: '16px',
									margin: 0,
									paddingLeft: 8,
									paddingRight: 8
								}}
							>
								Upload Words
							</label>

							<input id="file-input" type="file" onChange={handleFile} />
						</span>{' '}
					</div>
				</DialogTitle>
				<DialogContent>
					<TagsInput
						style={{ border: 'none', width: '100%' }}
						value={tags}
						onChange={tags => settags(tags)}
						inputProps={{
							placeholder: 'Add words to avoid',
							style: {
								width: 130
							}
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button style={{ color: 'white', backgroundColor: 'red' }} onClick={handleClose} color="primary">
						Cancel
					</Button>
					{props.addAvoidStatus ? (
						<Button color="primary">Loading...</Button>
					) : (
						<Button
							style={{ color: 'white', backgroundColor: '#2196f3' }}
							color="primary"
							onClick={submitTags}
						>
							Save
						</Button>
					)}
				</DialogActions>
			</Dialog>
			<FusePageSimple
				header={
					<div className="flex flex-1 items-center justify-between p-24">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">spellcheck</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography variant="h6" className="mx-12 hidden sm:flex">
									Words To Avoid
								</Typography>
							</FuseAnimate>
							<FuseAnimate>
								<Button variant="contained" color="secondary" onClick={handleClickOpen}>
									Add/Update Words To Avoid
								</Button>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					props.getAvoidWordsStatus ? (
						<FuseSplashScreen />
					) : (
						<div className="p-24 max-w-xl">
							{props.avoidedWords && props.avoidedWords.data && props.avoidedWords.data.length ? (
								<Table setOid={setOid} />
							) : (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<img
										src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
										style={{ width: '40%' }}
									/>
								</div>
							)}
						</div>
					)
				}
			/>
		</>
	);
}

const mapStateToProps = store => {
	return {
		parsedFile: store.fuse.usersReducer.parsedFile,
		avoidedWords: store.fuse.usersReducer.avoidedWords,
		getAvoidWordsStatus: store.fuse.usersReducer.getAvoidWordsStatus,
		addAvoidStatus: store.fuse.usersReducer.addAvoidStatus
	};
};

export default connect(mapStateToProps, { addAvoidWordsAction, fileParser, getAvoidWords })(ChangelogDoc);
