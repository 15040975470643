import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { getSubscriptionAction } from 'app/store/actions/fuse/subscription.action';
import { getUsersAction } from 'app/store/actions/fuse/usersActon';
import { connect } from 'react-redux';
import moment from 'moment';

function Widget2(props) {
	console.log(props.store);
	const wcurrentRange = 'DT';
	let ranges = {
		DT: 'Today',
		all: 'All'
	};

	console.log('getSubscriptionsData', props?.getSubscriptionsData);
	const getTodaysSubs = () => {
		let todaysubs = 0;
		todaysubs = props?.getSubscriptionsData?.filter(
			item => moment(item.current_period_start * 1000).isSame(moment(new Date()), 'day') === true
		);
		return todaysubs;
	};
	console.log('HERE', getTodaysSubs());
	let count = {
		DT: props.perDayUsers,
		all: props.getSubscriptionsData && props.getSubscriptionsData.length
	};
	const [currentRange, setCurrentRange] = useState(wcurrentRange);

	function handleChangeRange(ev) {
		setCurrentRange(ev.target.value);
	}

	React.useEffect(() => {
		props.getSubscriptionAction();
		props.getUsersAction();
	}, []);

	return (
		<Paper className="w-full rounded-8 shadow-none border-1">
			<div className="flex items-center justify-between px-4 pt-4">
				<Select
					className="px-12"
					native
					value={currentRange}
					onChange={handleChangeRange}
					inputProps={{
						name: 'currentRange'
					}}
					disableUnderline
				>
					{Object.entries(ranges).map(([key, n]) => {
						return (
							<option key={key} value={key}>
								{n}
							</option>
						);
					})}
				</Select>
			</div>
			<div className="text-center pt-12 pb-28">
				<Typography className="text-72 leading-none text-blue">
					{(currentRange === 'DT' ? getTodaysSubs()?.length : props?.getSubscriptionsData?.length) || 0}
				</Typography>
				<Typography className="text-16" color="textSecondary">
					Subscriptions
				</Typography>
			</div>
		</Paper>
	);
}

const mapStateToProps = store => {
	return {
		getSubscriptionsData: store.fuse.subscriptionReducer.subscriptions,
		perDayUsers: store.fuse.usersReducer.perDayUsers,
		store: store
	};
};

export default connect(mapStateToProps, { getSubscriptionAction, getUsersAction })(React.memo(Widget2));
