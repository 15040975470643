import React from 'react';
import { Dialog, Typography, Button, CircularProgress } from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';

const CommanModal = ({ open, onClose, title, content, render, actionsTitle, successHandler, loading, actions }) => {
	return (
		<div>
			<Dialog open={open} onClose={onClose}>
				<div style={{ padding: 20, minWidth: 340 }}>
					<div>
						<FuseAnimate animation="transition.slideLeftIn" delay={200}>
							{title && <Typography variant="h6">{title}</Typography>}
						</FuseAnimate>
					</div>
					{content && (
						<div style={{ marginTop: 15, marginBottom: 5 }}>
							<Typography variant="body1">{content}</Typography>
						</div>
					)}

					{render && render()}

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							gap: 10,
							marginTop: 15
						}}
					>
						{actions &&
							actions.map((item, index) => (
								<>
									<Button
										key={index}
										variant="contained"
										color="primary"
										onClick={item?.handler || null}
										style={item?.style || {}}
									>
										{item?.loading && item?.loading ? (
											<CircularProgress size={25} color="secondary" />
										) : (
											item?.title && item?.title
										)}
									</Button>
								</>
							))}
					</div>
				</div>
			</Dialog>
		</div>
	);
};

export default CommanModal;
