import React from 'react';
import authRoles from '../../auth/authRoles';
import UserManagement from './users-management';

const ECommerceAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/blocked-users',
			component: UserManagement
		},
		{
			exact: true,
			path: '/optir/dashboard/blocked-users/detail/:userNumber/:userId',
			component: React.lazy(() => import('./user-detail.js/userDetail'))
		}
	]
};

export default ECommerceAppConfig;
