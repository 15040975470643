import { GET_CREDENTIALS } from '../../actions/fuse/credentials-action';
const initialState = {
	credentials: [],
	userStatus: false,
	updateCredsStatus: false
};

const credentialsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_CREDS_REQUEST':
			return {
				...state,
				updateCredsStatus: true
			};
		case 'UPDATE_CREDS_SUCCESS':
			return {
				...state,
				updateCredsStatus: false
			};
		case 'UPDATE_CREDS_FAILED':
			return {
				...state,
				updateCredsStatus: false
			};
		case GET_CREDENTIALS:
			return {
				...state,
				credentials: action.payload
			};
		default: {
			return { ...state };
		}
	}
};

export default credentialsReducer;
