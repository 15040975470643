import React from 'react';
import logo from 'app/main/assets/optirblue.png';
import Loader from 'react-loader-spinner';

function FuseSplashScreen() {
	return (
		<div id="fuse-splash-screen">
			<div className="center">
				<div className="logo">
					<img width="150" src={logo} alt="logo" />
				</div>
				<center>
					<div style={{ marginTop: 10 }}>
						<Loader type="TailSpin" color="rgb(97, 218, 251)" height={50} width={50} />
					</div>
				</center>
				{/* <div className="spinner-wrapper">
					<div className="spinner">
						<div className="inner">
							<div className="gap" />
							<div className="left">
								<div className="half-circle" />
							</div>
							<div className="right">
								<div className="half-circle" />
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
}

export default React.memo(FuseSplashScreen);
