import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { FaGlobe, FaMobile } from 'react-icons/fa';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import VersionItem from './VersionItem';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-prevent-tab-${index}`,
		'aria-controls': `scrollable-prevent-tabpanel-${index}`
	};
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	}
}));

export default function VersionTab() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const { versions } = useSelector(({ fuse }) => fuse.versionReducer);
	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Tabs
					value={value}
					onChange={handleChange}
					variant="fullWidth"
					scrollButtons="off"
					centered
					aria-label="scrollable prevent tabs example"
				>
					<Tab icon={<FaGlobe />} label="Web" aria-label="Web" {...a11yProps(0)} />
					<Tab icon={<FaMobile />} label="Mobile" aria-label="mobile" {...a11yProps(1)} />
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				<Typography variant="h6" style={{ marginBottom: 10 }}>
					Web
				</Typography>

				<VersionItem name="web" data={versions?.web || []} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Typography variant="h6" style={{ marginBottom: 10 }}>
					Mobile
				</Typography>
				<VersionItem name="mobile" data={versions?.mobile || []} />
			</TabPanel>
		</div>
	);
}
