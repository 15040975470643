import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import { updateScoreAction } from '../../store/actions/fuse/usersActon';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import { showMessage } from 'app/store/actions';

const default_counts_managements = {
	certs: 10,
	core_skills: 60,
	education: 20,
	mgmt_level: 0,
	min_years_exp: 0,
	oid: '5fb53dede6114cbd72a94fa4',
	soft_skills: 5,
	title: 5
};
const sumValues = obj => {
	delete obj['oid'];
	console.log('OBJ', obj);
	return Object.values(obj).reduce((a, b) => a + b, 0);
};
function UsersTable({ counts_managements = default_counts_managements }) {
	const [ranges, setRanges] = useState(counts_managements);
	const [loader, setLoader] = useState(false);
	const [totalValue, setTotalValue] = useState(0);
	const dispatch = useDispatch();
	useEffect(() => {
		setTotalValue(sumValues(ranges));
	}, []);

	const stopLoader = () => {
		setLoader(false);
	};
	const onUpdate = () => {
		if (sumValues(ranges) > 100) {
			dispatch(showMessage({ message: 'Total value should not exceed 100' }));

			return;
		}
		if (!loader) {
			setLoader(true);
			const finalRanges = { ...ranges, oid: '5fb53dede6114cbd72a94fa4' };
			dispatch(updateScoreAction(finalRanges, stopLoader));
		}
	};

	const onChange = (key, value) => {
		setTotalValue(sumValues(ranges));

		let newRanges = {};
		let oldRanges = { ...ranges };
		delete oldRanges['oid'];
		let totalValue = sumValues(ranges);
		console.log('Available Value', 100 - parseInt(sumValues(ranges)));
		const availableLimit = 100 - parseInt(sumValues(ranges));
		Object.keys(oldRanges).map(k => {
			if (key === k) {
				newRanges[key] = Math.floor(value);
			} else {
				let v = oldRanges[key] > value ? oldRanges[k] : oldRanges[k];
				newRanges[k] = v > 0 ? Math.floor(v) : 0;
			}
		});

		setRanges(newRanges);
	};
	return (
		<div className="w-full flex flex-col">
			{Object.keys(ranges).map(n => {
				if (n == 'oid') return;
				return (
					<>
						<Typography style={{ paddingBottom: 30 }} id="discrete-slider-always" gutterBottom>
							{n}
						</Typography>

						<Slider
							disableSwap
							onChange={(e, value) => onChange(n, value)}
							value={parseInt(ranges[n])}
							aria-labelledby="discrete-slider-always"
							valueLabelDisplay="on"
						/>
					</>
				);
			})}
			<Typography>Total : {parseInt(totalValue)}</Typography>
			<Button variant="contained" color="primary" onClick={onUpdate} style={{ width: 112, float: 'right' }}>
				{loader ? 'loading' : 'update'}
			</Button>
		</div>
	);
}

export default UsersTable;
