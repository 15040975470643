import i18next from 'i18next';
import Dashboard from './dashboard';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';
import authRoles from '../../auth/authRoles';


i18next.addResourceBundle('en', 'examplePage', en);
i18next.addResourceBundle('tr', 'examplePage', tr);
i18next.addResourceBundle('ar', 'examplePage', ar);

const ExampleConfig = {
	settings: {
		layout: {
			config: {}
		}
    },
    auth:authRoles.admin,
	routes: [
		{
            exact:true,
			path: '/optir/dashboard',
			component: Dashboard
		}
	]
};

export default ExampleConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
