import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import UserTableHead from './userTableHead';
import { connect } from 'react-redux';
import { getAvoidWords } from '../../store/actions/fuse/usersActon';
import { addAvoidWordsAction } from 'app/store/actions/fuse/usersActon';
import { IconButton } from '@material-ui/core';
import { RemoveCircleOutlined } from '@material-ui/icons';
import DeleteModal from '@fuse/core/deleteModal/DeleteModal';

function UsersTable(props) {
	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	const [id, setid] = useState('');
	const [word, setWord] = useState(null);
	const [loader, setLoader] = useState(false);
	const [order, setOrder] = useState({
		direction: 'asc',
		id: null
	});
	const [modal, setModal] = useState(false);
	const handleModal = () => setModal(!modal);
	const callBack = () => {
		handleModal();
		setLoader(false);
	};
	const removeHandler = () => {
		let filtered = data.filter(item => item !== word);
		setData(filtered);
		let newData = {
			avoids: filtered,
			oid: id
		};
		setLoader(true);
		props.addAvoidWordsAction(newData, callBack);
	};

	useEffect(() => {
		if (props.avoidedWords) {
			setData(props.avoidedWords.data ? props.avoidedWords.data : []);
			setid(props.avoidedWords.oid ? props.avoidedWords.oid : '');
			props.setOid(props.avoidedWords.oid ? props.avoidedWords.oid : '');
		}
	}, [props.avoidedWords]);

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	return (
		<>
			<DeleteModal deleteHandler={removeHandler} handleModal={handleModal} loader={loader} modal={modal} />

			<div className="w-full flex flex-col">
				<FuseScrollbars className="flex-grow overflow-x-auto">
					<Table aria-labelledby="tableTitle">
						<UserTableHead
							numSelected={selected.length}
							order={order}
							onRequestSort={handleRequestSort}
							rowCount={data.length}
						/>

						<TableBody>
							{_.orderBy(
								data,
								[
									o => {
										switch (order.id) {
											case 'id': {
												return parseInt(id, 10);
											}
											case 'wordsToAvoid': {
												return 'wordsToAvoid';
											}
										}
									}
								],
								[order.direction]
							).map((item, index) => {
								return (
									<>
										<TableRow hover className="h-64 cursor-pointer">
											<TableCell component="th" scope="row">
												{item}
											</TableCell>
											<TableCell component="th" scope="row" style={{ textAlign: 'right' }}>
												<IconButton
													variant="contained"
													color="primary"
													onClick={() => {
														handleModal();
														setWord(item);
													}}
												>
													<RemoveCircleOutlined style={{ color: 'red' }} />
												</IconButton>
											</TableCell>
										</TableRow>
									</>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</div>
		</>
	);
}

const mapStateToProps = store => {
	return {
		avoidedWords: store.fuse.usersReducer.avoidedWords
	};
};

export default connect(mapStateToProps, { getAvoidWords, addAvoidWordsAction })(withRouter(UsersTable));
