import { GET_USERS, TOTAL_USERS_LENGTH, TODAYS_USERS_LENGTH, GET_ADMINS } from '../../actions/fuse/usersActon';

const initialState = {
	data: [],
	searchText: '',
	totalUsersLength: '',
	perDayUsers: '',
	avoidedWords: [],
	parsedFile: '',
	softSkills: [],
	countScore: null,
	getAvoidWordsStatus: false,
	getSkillsStatus: false,
	addAvoidStatus: false,
	addSkillsStatus: false,
	getUsersStatus: false,
	getAdminStatus: false,
	adminData: []
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_ADMIN_REQUEST':
			return {
				...state,
				getAdminStatus: true
			};
		case 'GET_USER_REQUEST':
			return {
				...state,
				getUsersStatus: true
			};

		case 'GET_USER_FAILED':
			return {
				...state,
				getUsersStatus: false
			};

		case 'ADD_SKILLS_REQUEST':
			return {
				...state,
				addSkillsStatus: true
			};

		case 'ADD_SKILLS_SUCCESS':
			return {
				...state,
				addSkillsStatus: false
			};

		case 'ADD_SKILLS_FAILED':
			return {
				...state,
				addSkillsStatus: false
			};

		case 'ADD_AVOID_REQUEST':
			return {
				...state,
				addAvoidStatus: true
			};
		case 'ADD_AVOID_FAILED':
			return {
				...state,
				addAvoidStatus: false
			};
		case 'ADDED_AVOID_WORDS':
			return {
				...state,
				addAvoidStatus: false
			};
		case 'GET_SKILLS_START':
			return {
				...state,
				getSkillsStatus: true
			};
		case 'GET_SKILLS_FAILED':
			return {
				...state,
				getSkillsStatus: false
			};
		case 'AVOID_WORDS_FETCH_START':
			return {
				...state,
				getAvoidWordsStatus: true
			};
		case 'AVOID_WORDS_FAILED':
			return {
				...state,
				getAvoidWordsStatus: false
			};
		case 'SAVE_SOFT_SKILLS':
			return {
				...state,
				softSkills: action.payload,
				getSkillsStatus: false
			};
		case GET_USERS:
			return {
				...state,
				data: action.payload,
				getUsersStatus: false
			};
		case GET_ADMINS:
			console.log('sami', { adminData: action.payload, getAdminStatus: false });
			return {
				...state,
				adminData: action.payload,
				getAdminStatus: false
			};
		case 'SAVE_AVOID_WORDS':
			return {
				...state,
				avoidedWords: action.payload,
				getAvoidWordsStatus: false
			};
		case 'SAVE_COUNT_SCORE':
			return {
				...state,
				countScore: action.payload
			};
		case 'PARSE_FILE':
			return {
				...state,
				parsedFile: action.payload
			};
		case TOTAL_USERS_LENGTH:
			return {
				...state,
				totalUsersLength: action.payload
			};
		case TODAYS_USERS_LENGTH:
			return {
				...state,
				perDayUsers: action.payload
			};
		default: {
			return state;
		}
	}
};

export default usersReducer;
