import React, { memo } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Button, Icon, InputBase, Typography } from '@material-ui/core';
import DeleteModal from '@fuse/core/deleteModal/DeleteModal';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { styles } from './styles';
import useTempMailFile from './useTempMailManagement';

const TempMailManagement = () => {
	const {
		emails,
		loader,
		email,
		setEmail,
		addEmail,
		deleteMail,
		updateMailValue,
		isUpdate,
		updateMail,
		renderKey,
		error,
		isUpload,
		uploadMail,
		tempInput,
		modal,
		handleModal,
		deleteEmailLoader
	} = useTempMailFile();

	return (
		<>
			<DeleteModal
				deleteHandler={deleteMail}
				handleModal={handleModal}
				loader={deleteEmailLoader}
				modal={modal}
			/>
			<FusePageSimple
				header={
					<div className="flex flex-1 items-center p-24">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">alternate_email</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
									Temp Mail Management
								</Typography>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					<div style={styles.contentContainer}>
						<FuseAnimate animation="transition.slideLeftIn" delay={300}>
							<div style={styles.addContainer}>
								<InputBase
									key={renderKey}
									value={email.email}
									style={{ borderBottom: '1px solid #e0e0e0', width: '80%' }}
									placeholder="Add Temp Mail Domain"
									onChange={e => {
										let temp = {
											email: e.target.value,
											oid: email?.oid || ''
										};
										setEmail(temp);
									}}
									inputProps={{ 'aria-label': 'Upload Job Description File' }}
								/>
								{error ? (
									<p style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>
										Temp mail Cannot be Empty
									</p>
								) : (
									''
								)}
								<div style={styles.ButtonDiv}>
									{loader ? (
										<Button variant="contained" color="primary" disabled style={styles.button}>
											Updating...
										</Button>
									) : (
										<Button
											variant="contained"
											color="primary"
											style={styles.button}
											onClick={() => {
												{
													isUpdate ? updateMail(email) : addEmail();
												}
											}}
										>
											{isUpdate ? 'Update' : 'Add'}
										</Button>
									)}
								</div>
								<div style={styles.ButtonDiv}>
									{
										<Button
											disabled={isUpload}
											variant="contained"
											color="primary"
											style={styles.button}
											onClick={() => {
												document.getElementById('tempMail').click();
											}}
										>
											{isUpload ? 'Uploading...' : 'Upload'}
										</Button>
									}
									<input
										type="file"
										name="tempMail"
										ref={tempInput}
										id="tempMail"
										style={{ display: 'none' }}
										onChange={e => {
											let data = e.target.files[0];
											uploadMail(data);
										}}
									/>
								</div>
							</div>
						</FuseAnimate>
						<div>
							<div style={styles.heading}>
								<Typography variant="h6" style={styles.headingText}>
									Temp Mail Domains
								</Typography>

								{emails?.map((item, index) => {
									return (
										<div style={styles.mailContainer} key={index}>
											<Typography variant="h6" style={styles.mailText}>
												{item.email}
											</Typography>
											<div style={styles.mailButtonDiv}>
												<Icon
													style={styles.editIcon}
													onClick={() => {
														updateMailValue(item.oid, item.email);
													}}
													className="edit-22"
													style={styles.deleteIcon}
												>
													edit
												</Icon>
												<Icon
													onClick={() => {
														handleModal(item.oid);
													}}
													className="text-22"
													style={{
														...styles.deleteIcon,
														color: 'red'
													}}
												>
													delete
												</Icon>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default memo(TempMailManagement);
