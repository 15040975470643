import React, { memo, useEffect } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Icon, Typography, TextField, MenuItem, CircularProgress } from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { CLEAR_VERSION_DATA, getVersions } from 'app/store/actions/fuse/version.action';
import CustomModal from 'app/components/CommanModal';
import { useDispatch, useSelector } from 'react-redux';
import useVersion from './useVersion';
import VersionTab from './VersionTab';

const Version = () => {
	const {
		addVersionLoader,
		setAddVersionLoader,
		addVersionModal,
		deviceName,
		setDeviceName,
		version,
		setVersion,
		deleteVersionHandler,
		dispatch,
		toggleAddModal,
		addVersionHandler,
		platforms,
		deleteVersionData,
		deleteLoader,
		deleteModalActions,
		addModalActions,
		loader,
		setLoader,
		versionDesc,
		setVersionDesc
	} = useVersion();
	useEffect(() => {
		setLoader(true);
		dispatch(
			getVersions(() => {
				setLoader(false);
			})
		);
	}, []);
	return (
		<>
			<CustomModal
				open={addVersionModal}
				onClose={toggleAddModal}
				title="Add New Version"
				content="Please select the platform and enter the version number"
				actions={addModalActions}
				render={() => (
					<>
						<div
							style={{
								marginTop: '10px'
							}}
						>
							<TextField
								fullWidth
								select
								color="primary"
								label="Platform"
								value={deviceName}
								required
								onChange={e => setDeviceName(e.target.value)}
							>
								{platforms.map((item, index) => (
									<MenuItem key={index} value={item.value}>
										{item.option}
									</MenuItem>
								))}
							</TextField>
						</div>
						<div
							style={{
								marginTop: '10px'
							}}
						>
							<TextField
								fullWidth
								color="primary"
								label="Version"
								placeholder="e.g. 1.0.0"
								value={version}
								required
								onChange={e => setVersion(e.target.value)}
							/>
						</div>
						<div
							style={{
								marginTop: '10px'
							}}
						>
							<textarea
								fullWidth
								rows={5}
								style={{
									width: '100%',
									outline: 'none',
									border: '1px solid #ccc'
								}}
								type="texarea"
								color="primary"
								label="Version"
								placeholder="description (optional)"
								value={versionDesc}
								required
								onChange={e => setVersionDesc(e.target.value)}
							/>
						</div>
					</>
				)}
			/>

			<CustomModal
				open={deleteVersionData?.isDeleteState}
				onClose={() => dispatch({ type: CLEAR_VERSION_DATA })}
				title="Delete Version"
				content={`Are you sure you want to delete ${deleteVersionData?.name}'s version ${deleteVersionData?.version}`}
				actions={deleteModalActions}
			/>

			<FusePageSimple
				header={
					<div className="flex flex-1 items-center p-24 justify-between">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">history</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
									Version Management
								</Typography>
							</FuseAnimate>
						</div>
						<div className="flex items-center">
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
									Add New Version
								</Typography>
							</FuseAnimate>
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32 cursor-pointer" onClick={toggleAddModal}>
									add_circle
								</Icon>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					loader ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: 'max-content'
							}}
						>
							<CircularProgress color="primary" size={25} />
						</div>
					) : (
						<VersionTab />
					)
				}
			/>
		</>
	);
};

export default memo(Version);
