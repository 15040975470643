import { GET_EMAIL_TEMPLATES, UPDATE_EMAIL_TEMPLATE } from 'app/store/actions/fuse/email-template-action';

const initialState = {
    fieldTemplates: {},
};

const emailTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMAIL_TEMPLATES:
        case UPDATE_EMAIL_TEMPLATE: {
            return {
                ...state,
                fieldTemplates: action.payload,
            };
        };

        default: {
            return state;
        }
    }
};

export default emailTemplateReducer;