const initialState = {
	notifications: []
};
// eslint-disable-next-line import/prefer-default-export
export default function notificationReducer(state = initialState, action) {
	switch (action.type) {
		case 'GET_NOTIFICATION':
			return {
				...state,
				notifications: action.payload
			};
		// case 'ADD_NOTIFICATION':
		// 	return {
		// 		...state,
		// 		notifications: [...state.notifications, action.payload]
		// 	};

		default:
			return state;
	}
}
