export const styles = {
	contentContainer: {
		width: '95%',
		margin: 'auto',
		padding: '20px'
	},
	ButtonDiv: {
		display: 'inline-block',
		marginLeft: 10
	},
	button: {
		display: 'flex',
		marginLeft: 'auto'
	},
	inputContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	inputDiv: {
		width: '48.5%'
	},
	textarea: {
		marginTop: '10px',
		width: '100%',
		height: '460px',
		padding: '10px',
		resize: 'none',
		overflow: 'auto',
		fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
		boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)'
	},
	paperField: {
		padding: '2px 4px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%'
	},
	divider: {
		height: 28,
		margin: 4
	},
	iconContainer: {
		padding: 10,
		cursor: 'pointer'
	},
	addContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	mailContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '10px',
		width: '100%',
		marginTop: '10px',
		borderBottom: '2px solid #e0e0e0'
	},
	editIcon: {
		cursor: 'pointer'
	},
	mailButtonDiv: {
		cursor: 'pointer',
		gap: '10px'
	}
};
