import { REQUEST_FOR_SAVE, SAVE_COUPON_SUCCESSFULLY, SAVE_COUPON_FAILURE,FETCHED_COUPON } from '../../actions/fuse/coupon-action';

const initialState = {
	saveStatus: false,
	allCoupons:[],
	getCouponStatus:false
};

const couponReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_COUPON_STATR":
			return {
				...state,
				getCouponStatus: true
			};
		case "GET_COUPON_FAILED":
			return {
				...state,
				getCouponStatus: false
			};
		case REQUEST_FOR_SAVE:
			return {
				...state,
				saveStatus: true
			};
		case SAVE_COUPON_SUCCESSFULLY:
			return {
				...state,
				saveStatus: false
			};
		case SAVE_COUPON_FAILURE:
			return {
				...state,
				saveStatus: false
			};
		case FETCHED_COUPON:
			return {
				...state,
				allCoupons: action.payload,
				getCouponStatus: false
			};
		default: {
			return { ...state };
		}
	}
};

export default couponReducer;
