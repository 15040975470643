import authRoles from '../../auth/authRoles';
import DefaultFile from './DefaultFile';

const defaultFileConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/default-files',
			component: DefaultFile
		}
	]
};

export default defaultFileConfig;
