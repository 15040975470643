import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import { url } from '../../endpoint';
/* eslint-disable camelcase */

class UserManagementService extends FuseUtils.EventEmitter {
	getUsers = (page, rowsPerPage) => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-users?page=${page}&per_page=${rowsPerPage}`, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getBlockUsers = (page, rowsPerPage) => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-block-users?page=${page}&per_page=${rowsPerPage}`, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getAdmin = (page, rowsPerPage) => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/getAdmin?page=${page}&per_page=${rowsPerPage}`, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	AvoidWords = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-avoid-words`, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	CountScore = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-avoid-words`, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	AddAvoidWords = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/add-avoid-words`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	AddAdmin = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/addAdmin`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	AddSoftSkills = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/add-soft-skills`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	UpdateScore = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/add-update-count-management`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getSoftSkills = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-soft-skills`, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getUserSubsription = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/get-user-subscription`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	refundUserPayment = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/refund-payment`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	updateUser = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/update-user`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	deleteAdmin = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/deleteAdmin`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	updateAdmin = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/updateAdmin`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getNotification = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/getNotification`, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	addNotification = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/addNotification`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	updateNotification = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/updateNotification`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	deleteNotification = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/deleteNotification`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	deleteUser = data => {
		const headers = {
			'Content-Type': 'application/json'
		};

		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/delete-user-by-admin`, data, {
					headers
				})
				.then(resolve)
				.catch(reject);
		});
	};
}

const instance = new UserManagementService();

export default instance;
