import authRoles from '../../auth/authRoles'
import AvoidWordManagement from './coupon';

const CouponConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/words-to-avoid',
			component: AvoidWordManagement
		}
	]
};

export default CouponConfig;
