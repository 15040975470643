import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useState } from 'react';
import JWTLoginTab from './tabs/JWTLoginTab';
import logo from '../assets/optirblue.png';

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	}
}));

function Login(props) {
	const classes = useStyles();
	const [selectedTab, setSelectedTab] = useState(0);

	function handleTabChange(event, value) {
		setSelectedTab(value);
	}
	
	return (
		<div className={clsx(classes.root, 'flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0')}>
			<div
				className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left"
				style={{ display: 'flex', justifyContent: 'center' }}
			>
				<FuseAnimate animation="transition.expandIn">
					<img className="w-128 mb-32" src={logo} alt="logo" style={{ width: '16.8rem' }} />
				</FuseAnimate>

				<FuseAnimate animation="transition.slideUpIn" delay={300}>
					<Typography variant="h3" color="inherit" className="font-light">
						Welcome to the RESUMAS SuperAdmin!
					</Typography>
				</FuseAnimate>
			</div>

			<FuseAnimate animation={{ translateX: [0, '100%'] }}>
				<Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
					<CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
						<Typography variant="h6" className="text-center md:w-full mb-48">
							LOGIN TO YOUR ACCOUNT
						</Typography>

						<Tabs
							value={selectedTab}
							onChange={handleTabChange}
							variant="fullWidth"
							className="w-full mb-32"
						>
							<Tab
								icon={
									<img
										className="h-40 p-4 bg-black rounded-12"
										src={logo}
										alt="firebase"
										style={{ backgroundColor: 'white', position: 'relative', bottom: 10 }}
									/>
								}
								className="min-w-0"
								// label="RESUMAS"
							/>
						</Tabs>

						{selectedTab === 0 && (
							<JWTLoginTab
								cb={() =>
									props.history.push({
										pathname: '/optir/dashboard'
									})
								}
							/>
						)}
					</CardContent>
				</Card>
			</FuseAnimate>
		</div>
	);
}

export default Login;
