import Axios from 'axios';
import { url } from '../../../endpoint';
import { showMessage } from './message.actions';

export const CHANGE_STATE = 'VIDEO_CHANGE_STATE';

export const getVideos = () => async dispatch => {
	try {
		let res = await Axios.get(`${url}/get-video`);

		if (res.data.status === 200) {
			dispatch({
				type: CHANGE_STATE,
				payload: res.data.data
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const updateVideos = (data, cb) => async dispatch => {
	try {
		const res = await Axios.post(`${url}/admin/update-video`, {
			videos: data
		});

		if (res.data.status === 200) {
			dispatch({
				type: CHANGE_STATE,
				payload: data
			});
			if (cb) {
				cb();
			}
		}
	} catch (error) {
		dispatch(showMessage({ message: error.message }));
	}
};
