import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SubscriptionTableHead from './subscription-table-head';
import { Pagination } from '@material-ui/lab';
import { getSubscriptionAction } from 'app/store/actions/fuse/subscription.action';
import { connect } from 'react-redux';
import moment from 'moment';
import FuseSplashScreen from '@fuse/core/fuseSplash2';

const activeStatus = {
	color: 'white',
	backgroundColor: '#4caf50',
	padding: '.4rem',
	borderRadius: '.4rem',
	textAlign: 'center'
};

const blockStatus = {
	color: 'white',
	backgroundColor: '#d32f2f',
	padding: '.4rem',
	borderRadius: '.4rem',
	textAlign: 'center'
};

function SubscriptionTable(props) {
	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	// const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [order, setOrder] = useState({
		direction: 'asc',
		id: null
	});

	useEffect(() => {
		props.getSubscriptionAction();
	}, []);

	useEffect(() => {
		setData(props.getSubscriptionsData);
	}, [props.getSubscriptionsData]);

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		console.log(order.id);

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	function handleClick(item, index) {
		props.history.push(`/optir/dashboard/subscription-management/detail/${item.user_id}`, item);
	}

	var filteredData = data;
	const column = ['name', 'email'];
	let searchStringForData = props.sendSText.trim().toLowerCase();
	if (searchStringForData.length > 0) {
		filteredData = filteredData.filter(item => {
			// return item.firstName.toLowerCase().match(searchStringForData);
			return Object.keys(item).some(key => {
				return column.includes(key)
					? item[key]
							.toString()
							.toLowerCase()
							.includes(searchStringForData)
					: console.log('key', key);
			});
		});
	}
	if (props.subDataStatus) {
		return <FuseSplashScreen />;
	} else
		return props.getSubscriptionsData ? (
			<div className="w-full flex flex-col">
				<FuseScrollbars className="flex-grow overflow-x-auto">
					<Table className="min-w-xl" aria-labelledby="tableTitle">
						<SubscriptionTableHead
							numSelected={selected.length}
							order={order}
							onRequestSort={handleRequestSort}
							rowCount={filteredData && filteredData.length}
						/>

						<TableBody>
							{_.orderBy(
								filteredData,
								[
									o => {
										switch (order.id) {
											case 'id': {
												return parseInt(o.id, 10);
											}
											case 'name': {
												return o.name;
											}
											case 'subscription': {
												return o.subscription_id;
											}
											case 'email': {
												return o.email;
											}
											case 'amount': {
												return o.amount;
											}
											case 'start': {
												return o.current_period_start;
											}
											case 'end': {
												return o.current_period_end;
											}
											case 'active': {
												return o.active;
											}
										}
									}
								],
								[order.direction]
							).map((items, index) => {
								return (
									<TableRow
										key={index}
										onClick={event => handleClick(items, index)}
										hover
										className="h-64 cursor-pointer"
									>
										<TableCell component="th" scope="row">
											{items.name}
										</TableCell>
										<TableCell component="th" scope="row">
											{items.email}
										</TableCell>
										<TableCell component="th" scope="row">
											${items?.items?.data[0]?.plan?.amount / 100}
										</TableCell>
										<TableCell component="th" scope="row">
											{items?.id}
										</TableCell>
										<TableCell component="th" scope="row">
											{moment(items.current_period_start * 1000).format('MMMM DD YYYY')}
										</TableCell>
										<TableCell component="th" scope="row">
											{moment(items.current_period_end * 1000).format('MMMM DD YYYY')}
										</TableCell>
										<TableCell component="th" scope="row">
											{items.status === 'active' ? 'yes' : 'no'}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>

				{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
				<Pagination
					count={Math.ceil(props.totalLenth / rowsPerPage)}
					variant="outlined"
					color="secondary"
					onChange={handleChangePage}
				/>
			</div> */}
			</div>
		) : (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
				<img
					src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
					style={{ width: '40%' }}
				/>
			</div>
		);
}
const mapStateToProps = store => {
	return {
		getSubscriptionsData: store.fuse.subscriptionReducer.subscriptions,
		subDataStatus: store.fuse.subscriptionReducer.subDataStatus
	};
};
export default connect(mapStateToProps, { getSubscriptionAction })(withRouter(SubscriptionTable));
