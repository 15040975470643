import FusePageSimple from '@fuse/core/FusePageSimple';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ChangelogCard from './couponCards';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './css.css';
import From from './data-time-for-newCoupon/from';
import Upto from './data-time-for-newCoupon/upto';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { showMessage } from 'app/store/actions/fuse';
import store from 'app/store/';
import { addSkillsAction, fileParser } from 'app/store/actions/fuse/usersActon';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { getSkillsAction } from '../../store/actions/fuse/usersActon';

import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import Table from './usersTable';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import FuseSplashScreen from '@fuse/core/fuseSplash2';

function ChangelogDoc(props) {
	const [open, setOpen] = React.useState(false);
	const [coupenName, setCouponName] = React.useState('');
	const [percentDiscount, setPercentDiscount] = React.useState('');
	const [validFrom, setValidFrom] = React.useState('');
	const [validUpTo, setValidUpTo] = React.useState('');
	const [venmoPlanId, setvenmoPlanId] = React.useState('');
	const [tags, settags] = React.useState([]);
	const [oid, setOid] = React.useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	React.useEffect(() => {
		props.getSkillsAction();
	}, []);
	const handleFile = e => {
		let fileObj = e.target.files[0];

		ExcelRenderer(fileObj, (err, resp) => {
			if (err) {
				console.log(err);
			} else {
				let getTags = [];
				// console.log(resp.rows[0]);
				resp.rows.map(item => {
					getTags.push(item[0]);
				});
				settags(getTags);
				//   setCols(resp.cols)
				//   setRows(resp.rows);
			}
		});
		// console.log(e.target.files[0].type.split('application/vnd.openxmlformats-officedocument.spreadsheetml.')[1]);
		// if (['pdf', 'docx', 'xlsx'].includes(e.target.files[0].type.split('/')[1])) {
		// props.fileParser(e.target.files[0]);
		// } else {
		// toastr.error('please upload pdf and docx only');
		// }
	};

	const submitTags = () => {
		if (tags.length) {
			let data = {
				oid: oid,
				skills: tags
			};
			props.addSkillsAction(data);
		} else {
		}
	};

	return (
		<>
			<Dialog className="couponDialogBox" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Add Skills</span>
						<span class="image-upload">
							<label
								for="file-input"
								style={{
									fontWeight: '600',
									color: 'white',
									cursor: 'pointer',
									fontSize: '16px',
									margin: 0,
									paddingLeft: 8,
									paddingRight: 8
								}}
							>
								Upload Skills
							</label>

							<input id="file-input" type="file" onChange={handleFile} />
						</span>{' '}
					</div>
				</DialogTitle>
				<DialogContent>
					<TagsInput
						style={{ border: 'none', width: '100%' }}
						value={tags}
						onChange={tags => settags(tags)}
						inputProps={{
							placeholder: 'Add Skills'
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					{/* <Button color="primary" disabled={true}>
							<Loader type="Bars" color="#182D3E" height={24} width={20} />
						</Button> */}
					{props.addSkillsStatus ? (
						<Button color="primary">Loading...</Button>
					) : (
						<Button color="primary" onClick={submitTags}>
							Save
						</Button>
					)}
				</DialogActions>
			</Dialog>
			<FusePageSimple
				header={
					<div className="flex flex-1 items-center justify-between p-24">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">note</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography variant="h6" className="mx-12 hidden sm:flex">
									Skills Management
								</Typography>
							</FuseAnimate>
							<FuseAnimate>
								<Button variant="contained" color="primary" onClick={handleClickOpen}>
									Add Skills
								</Button>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					props.getSkillsStatus ? (
						<FuseSplashScreen />
					) : (
						<div className="p-24 max-w-xl">
							{props.softSkills && props.softSkills.data && props.softSkills.data.length ? (
								<Table setOid={setOid} />
							) : (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<img
										src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
										style={{ width: '40%' }}
									/>
								</div>
							)}
						</div>
					)
				}
			/>
		</>
	);
}

const mapStateToProps = store => {
	return {
		parsedFile: store.fuse.usersReducer.parsedFile,
		softSkills: store.fuse.usersReducer.softSkills,
		getSkillsStatus: store.fuse.usersReducer.getSkillsStatus,
		addSkillsStatus: store.fuse.usersReducer.addSkillsStatus
	};
};

export default connect(mapStateToProps, { addSkillsAction, fileParser, getSkillsAction })(ChangelogDoc);
