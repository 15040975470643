import React from 'react';
import authRoles from '../../auth/authRoles';

import AvoidWordManagement from './count';

const CouponConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/sovren-management',
			component: AvoidWordManagement
		}
	]
};

export default CouponConfig;
