import AddAdminConfig from 'app/main/addAdmin/addAdminConfig';
import defaultFileConfig from 'app/main/default-files/DefaultFileConfig';
import TopSoftSkillConfig from 'app/main/topSoftSkills/topSoftSkillsConfig';
import EmailTemplateConfig from 'app/main/email-templates/EmailTemplateConfig';
import videosConfig from 'app/main/videos/VideosConfig';
import NotificationHandlerConfig from 'app/main/notification-handler/NotificationHandlerConfig';
import DashboardConfig from '../main/Dashboard/DashboardConfig';
import UserConfig from '../main/users-management/usersConfig';
import BlockUsers from '../main/block-users/usersConfig';
import CredentialsConfig from '../main/contacts/ContactsAppConfig';
import SubscriptionAppConfig from '../main/subscription-management/subscriptionConfig';
import CouponConfig from '../main/Discount-coupons/CouponConfig';
import WordsToAvoidConfig from '../main/words-to-avoid/wordsToAvoidConfig';
import skillsConfig from '../main/skills-management/skillsConfig';
import countConfig from '../main/count-managemts/countConfig';
import sovrenConfig from '../main/sovren-managements/countConfig';
import refundConfig from '../main/refund-managements/countConfig';
import CoverConfig from '../main/cover-managements/countConfig';
import tempMailManagementConfig from '../main/temp-mail-management/TempMailManagementConfig';
import jsonUploaderConfig from '../main/json-uploader/JsonUploaderConfig';
import versionConfig from '../main/version-management/VersionsConfig';

const appsConfigs = [
	DashboardConfig,
	UserConfig,
	CredentialsConfig,
	SubscriptionAppConfig,
	CouponConfig,
	WordsToAvoidConfig,
	skillsConfig,
	countConfig,
	sovrenConfig,
	refundConfig,
	CoverConfig,
	defaultFileConfig,
	TopSoftSkillConfig,
	EmailTemplateConfig,
	videosConfig,
	versionConfig,
	tempMailManagementConfig,
	jsonUploaderConfig,
	AddAdminConfig,
	NotificationHandlerConfig,
	BlockUsers
];

export default appsConfigs;
