import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import { url } from '../../endpoint';
/* eslint-disable camelcase */

class CredentialService extends FuseUtils.EventEmitter {
	getCredentials = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-credentials`, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	updateCredentials = (data) => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/update-credentials`, data, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};
}



const instance = new CredentialService();

export default instance;
