import authRoles from '../../auth/authRoles';
import NotificationHandler from './NotificationHandler';

const NotificationHandlerConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/notification-handler',
			component: NotificationHandler
		}
	]
};

export default NotificationHandlerConfig;
