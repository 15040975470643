import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { logoutAction } from 'app/store/actions/fuse/auth.actions';
import { withRouter } from 'react-router-dom';

function UserMenu(props) {
	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};
	const name = useSelector(state => state?.fuse?.loginReducer.name) || 'Admin';

	return (
		<>
			<Button className="h-64" onClick={userMenuClick}>
				<Avatar
					className=""
					alt="user photo"
					src="https://www.kindpng.com/picc/m/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png"
				/>

				<div className="hidden md:flex flex-col mx-12 items-start">
					<Typography component="span" className="normal-case font-600 flex">
						{name}
					</Typography>
					<Typography className="text-11 capitalize" color="textSecondary">
						admin
					</Typography>
				</div>

				<Icon className="text-16 hidden sm:flex" variant="action">
					keyboard_arrow_down
				</Icon>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				<MenuItem
					onClick={() => {
						userMenuClose();
						props.logoutAction(() => props.history.replace('/login'));
					}}
				>
					<ListItemIcon className="min-w-40">
						<Icon>exit_to_app</Icon>
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</MenuItem>
			</Popover>
		</>
	);
}

export default connect(null, { logoutAction })(withRouter(UserMenu));
