import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import { url } from '../../endpoint';
/* eslint-disable camelcase */

class CouponService extends FuseUtils.EventEmitter {
	saveCoupon = (data) => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/create-coupon`, data, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getCoupon = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-coupon`, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};
	delCoupon = (couponCode) => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/delete-coupon`, couponCode, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};
}



const instance = new CouponService();

export default instance;
