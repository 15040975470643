import SubscriptionService from 'app/services/subscriptionService';
import { showMessage } from 'app/store/actions/fuse';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_REFUND_SUBSCRIPTIONS = 'GET_REFUND_SUBSCRIPTIONS';
export const GET_COVER_SUBSCRIPTIONS = 'GET_COVER_SUBSCRIPTIONS';

export const getSubscriptionAction = data => async dispatch => {
	try {
		// console.log('came here')
		dispatch({
			type: 'SUB_GET_REQUEST'
		});
		const res = await SubscriptionService.getSubscriptions();
		console.log(res);
		if (res.data.status === 200) {
			dispatch({
				type: GET_SUBSCRIPTIONS,
				payload: res.data.data
			});
		} else {
			dispatch({
				type: 'SUB_GET_FAILED'
			});
		}
	} catch (err) {
		dispatch({
			type: 'SUB_GET_FAILED'
		});
		console.log(err);
	}
};

export const getRefundSubscriptionAction = cb => async dispatch => {
	try {
		// console.log('came here')
		dispatch({
			type: 'SUB_REFUND_GET_REQUEST'
		});
		const res = await SubscriptionService.getRefundSubscriptions();
		if (res.data.status === 200) {
			dispatch({
				type: GET_REFUND_SUBSCRIPTIONS,
				payload: res.data.refunds
			});
		} else {
			dispatch({
				type: 'SUB_REFUND_GET_FAILED'
			});
		}
	} catch (err) {
		dispatch({
			type: 'SUB_REFUND_GET_FAILED'
		});
		console.log(err);
	}
};

export const getCoverAction = cb => async dispatch => {
	try {
		dispatch({
			type: 'SUB_REFUND_GET_REQUEST'
		});
		const res = await SubscriptionService.getCoverManagements();
		if (res.data.status === 200) {
			dispatch({
				type: GET_COVER_SUBSCRIPTIONS,
				payload: res.data.covers
			});
		} else {
			dispatch({
				type: 'SUB_REFUND_GET_FAILED'
			});
		}
	} catch (err) {
		dispatch({
			type: 'SUB_REFUND_GET_FAILED'
		});
		console.log(err);
	}
};

export const saveCoverLetterAction = (data, cb) => async dispatch => {
	try {
		const res = await SubscriptionService.saveCoverLetter(data);
		if (res.data.status === 200) {
			dispatch(showMessage({ message: 'Saved successfully' }));
			if (cb) {
				cb();
			}
		} else {
			dispatch(showMessage({ message: res.data.message }));
		}
	} catch (err) {
		dispatch(showMessage({ message: err.message }));
	}
};

export const updateCoverLetterAction = (data, cb) => async dispatch => {
	try {
		const res = await SubscriptionService.updateCoverLetter(data);
		if (res.data.status === 200) {
			dispatch(showMessage({ message: 'Updated successfully' }));
			if (cb) {
				cb();
			}
		} else {
			dispatch(showMessage({ message: res.data.message }));
		}
	} catch (err) {
		dispatch(showMessage({ message: err.message }));
	}
};

export const deleteCoverLetterAction = (data, cb) => async dispatch => {
	try {
		const res = await SubscriptionService.deleteCoverLetter(data);
		if (res.data.status === 200) {
			dispatch(showMessage({ message: 'Deleted successfully' }));
			if (cb) {
				cb();
			}
		} else {
			dispatch(showMessage({ message: res.data.message }));
		}
	} catch (err) {
		dispatch(showMessage({ message: err.message }));
	}
};
