import authRoles from '../../auth/authRoles';
import topSoftSkills from './topSoftSkills';

const TopSoftSkillConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/top-soft-skills',
			component: topSoftSkills
		}
	]
};

export default TopSoftSkillConfig;
