import authRoles from '../../auth/authRoles';
import Videos from './Videos';

const videosConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/videos-apis',
			component: Videos
		}
	]
};

export default videosConfig;
