import Axios from 'axios';
import { url } from '../../../endpoint';
import { showMessage } from './message.actions';

export const CHANGE_STATE = 'CHANGE_STATE';

export const getDefaultFiles = () => async (dispatch) => {
    try {
        let res = await Axios.get(`${url}/admin/get-resume-doc`);

        if (res.data.status === 200) {
            dispatch({
                type: CHANGE_STATE,
                payload: res.data.data
            });
        }
    } catch (error) {
        console.log(error);
    }
}

export const updateDefaultFiles = (state, files, setLoader) => async (dispatch) => {
    try {
        setLoader(true);
        const formData = new FormData();
        formData.append('resumeFile', files.resume);
        formData.append('jobFile', files.jobDescription);
        formData.append('data', JSON.stringify(state));

        let res = await Axios.post(`${url}/admin/add-resume-doc`, formData);

        if (res.data.status === 200) {
            dispatch({
                type: CHANGE_STATE,
                payload: state
            });
            dispatch(showMessage({ message: 'Successfully Updated' }));
        }

        setLoader(false);

    } catch (error) {
        dispatch(showMessage({ message: error.message }));
        setLoader(false);
    }
}
export const fileParser = (file, cb, key, closeLoader) => async (dispatch) => {
    try {
        const files = new FormData();
        files.append("file", file);
        let token = await localStorage.getItem('adminAcessToken');
        const res = await Axios.post(`${url}/admin/upload-resume`, files, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
        if (res.data.status === 200) {
        cb(key, res.data.response);
        } else {
        dispatch(showMessage({ message: res.data.message }));
        closeLoader();
        }

    } catch (error) {
        dispatch(showMessage({ message: error.message }));
        cb();
    }
};