import FusePageSimple from '@fuse/core/FusePageSimple';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ChangelogCard from './couponCards';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './css.css';
import From from './data-time-for-newCoupon/from';
import Upto from './data-time-for-newCoupon/upto';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { showMessage } from 'app/store/actions/fuse';
import store from 'app/store/';
import { saveCouponAction, getCouponAction } from 'app/store/actions/fuse/coupon-action';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import FuseSplashScreen from '@fuse/core/fuseSplash2';

const changelogData = [
	{
		id: '1',
		couponName: 'Blessed Friday Voucher	',
		percentDiscount: '32%',
		couponCode: '79846y887hbd23',
		validFrom: 'December 22nd 12:00 am',
		validUpTo: 'December 22nd 12:00 am'
	},
	{
		id: '2',
		couponName: 'Saturday Voucher	',
		percentDiscount: '42%',
		couponCode: '79846y887hbd23',
		validFrom: 'December 22nd 12:00 am',
		validUpTo: 'December 22nd 12:00 am'
	}
];

function ChangelogDoc(props) {
	const [open, setOpen] = React.useState(false);
	const [coupenName, setCouponName] = React.useState('');
	const [percentDiscount, setPercentDiscount] = React.useState('');
	const [validFrom, setValidFrom] = React.useState('');
	const [validUpTo, setValidUpTo] = React.useState('');
	// const [venmoPlanId, setvenmoPlanId] = React.useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	React.useEffect(() => {
		props.getCouponAction();
	}, []);

	React.useEffect(() => {
		if (props.isStatusTrue) {
			setCouponName('');
			setPercentDiscount('');
			setValidFrom('');
			setValidUpTo('');
		}
	}, [props.isStatusTrue]);

	const submitForm = () => {
		if (coupenName === '' || percentDiscount === '' || validFrom === '' || validUpTo === '') {
			store.dispatch(showMessage({ message: 'fields cannot be empty!' }));
		} else {
			const status = new Date(validUpTo).toISOString() !== new Date(validFrom).toISOString();
			const futureDate =
				new Date(validUpTo).toISOString() > new Date(validFrom).toISOString() &&
				new Date().toISOString() < new Date(validFrom).toISOString();
			if (status && futureDate) {
				const data = {
					name: coupenName,
					discount: percentDiscount,
					validTo: new Date(validUpTo).toISOString(),
					validFrom: new Date(validFrom).toISOString()
				};
				props.saveCouponAction(data, () => {
					handleClose();
					props.getCouponAction();
				});
			} else if (!status) {
				store.dispatch(showMessage({ message: 'Choose different Date' }));
			} else if (!futureDate) {
				store.dispatch(showMessage({ message: 'Select correct Date' }));
			}
		}
	};

	return (
		<>
			<Dialog className="couponDialog" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Add New Coupon</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="couponname"
						label="Coupon Name"
						type="text"
						value={coupenName}
						onChange={e => setCouponName(e.target.value)}
						fullWidth
					/>
					{/* <TextField
						autoFocus
						margin="dense"
						id="venmoPlanId"
						label="Venmo Plan Id"
						type="text"
						value={venmoPlanId}
						onChange={e => setvenmoPlanId(e.target.value)}
						fullWidth
					/> */}
					<TextField
						autoFocus
						margin="dense"
						id="percentDiscount"
						label="Percent Discount"
						type="text"
						value={percentDiscount}
						onChange={e => setPercentDiscount(e.target.value)}
						fullWidth
					/>
					<div>
						<div style={{ marginTop: 10, marginBottom: 10 }}>
							<EventAvailableIcon style={{ marginRight: 10 }} />
							Valid From
							<From setFrom={value => setValidFrom(`${value}`)} />
						</div>
						<div style={{ marginTop: 10, marginBottom: 10 }}>
							<EventBusyIcon style={{ marginRight: 10 }} />
							Valid Upto
							<Upto setUpto={value => setValidUpTo(`${value}`)} />
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					{props.isStatusTrue ? (
						<Button color="primary">Loading...</Button>
					) : (
						<Button onClick={submitForm} color="primary">
							Save
						</Button>
					)}
				</DialogActions>
			</Dialog>
			<FusePageSimple
				header={
					<div className="flex flex-1 items-center justify-between p-24">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">key</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography variant="h6" className="mx-12 hidden sm:flex">
									Coupon Management
								</Typography>
							</FuseAnimate>
							<FuseAnimate>
								<Button variant="contained" color="secondary" onClick={handleClickOpen}>
									Add New Coupon
								</Button>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					props.getCouponStatus ? (
						<FuseSplashScreen />
					) : (
						<div className="p-24 max-w-xl">
							{props.getCoupons.length ? (
								props.getCoupons.map(item => (
									<ChangelogCard className="mb-24" key={item.coupnId} {...item} />
								))
							) : (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<img
										src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
										style={{ width: '40%' }}
									/>
								</div>
							)}
						</div>
					)
				}
			/>
		</>
	);
}
const mapStateToProps = store => {
	return {
		isStatusTrue: store.fuse.couponReducer.saveStatus,
		getCoupons: store.fuse.couponReducer.allCoupons,
		getCouponStatus: store.fuse.couponReducer.getCouponStatus
	};
};
export default connect(mapStateToProps, { saveCouponAction, getCouponAction })(ChangelogDoc);
