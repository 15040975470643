import jwtService from 'app/services/jwtService';
import { showMessage } from 'app/store/actions/fuse';
import history from '@history';
import axios from 'axios';
import { setNavigation } from './navigation.actions';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CURRENT_LOGIN_STATUS = 'CURRENT_LOGIN_STATUS';
export const ADMIN_PERMISSION = 'ADMIN_PERMISSION';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const signInAction = data => async (dispatch, getState) => {
	try {
		dispatch({ type: LOGIN_REQUEST });
		const res = await jwtService.signInWithEmailAndPassword(data);

		if (res.data.status === 200) {
			axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;
			localStorage.setItem('adminAcessToken', res.data.access_token);

			const currentNavigation = getState().fuse.navigation;
			if (res?.data?.superAdmin) {
				dispatch(setNavigation(currentNavigation));
			} else if (res.data?.permissions?.length && !res?.data?.superAdmin) {
				const navigationKeys = res.data.permissions;
				const filteredNav = currentNavigation[0]?.children?.filter(item => {
					return navigationKeys.some(page => {
						return item.id === page.pageId;
					});
				});
				const newNavigation = [
					{
						id: 'applications',
						title: 'Applications',
						translate: 'APPLICATIONS',
						type: 'group',
						icon: 'apps',
						children: filteredNav
					}
				];
				dispatch(setNavigation(newNavigation));
			}
			dispatch({
				type: LOGIN_SUCCESS
			});
			dispatch({
				type: 'CURRENT_USER_STATUS',
				payload: res.data.name
			});
			dispatch({
				type: 'ADMIN_PERMISSION',
				payload: res.data.permissions
			});
			dispatch(showMessage({ message: 'Successfully logged in!' }));
		} else {
			console.log('err');
			dispatch({
				type: LOGIN_FAILURE
			});
			dispatch(showMessage({ message: 'email or password is incorrect!' }));
		}
	} catch (err) {
		dispatch({
			type: LOGIN_FAILURE
		});
		dispatch(showMessage({ message: err.message }));
	}
};

// export const getCurrentLoginStatusAction = data => async dispatch => {
// 	try {
// 		const res = await jwtService.getCurrentLoginStatus();
// 		console.log('new response', res.data.isAdminLoggedIn);
// 		if (res) {
// 			if (!res.data.isAdminLoggedIn) {
// 				dispatch({
// 					type: CURRENT_LOGIN_STATUS
// 				});
// 			} else {
// 				console.log('user is not logged in');
// 			}
// 		}
// 	} catch (err) {
// 		dispatch(showMessage({ message: err.message }));
// 	}
// };

export const logoutAction = data => async dispatch => {
	try {
		const res = await jwtService.logoutService();
		if (res) {
			localStorage.clear();
			sessionStorage.clear();
			dispatch({ type: LOGOUT_SUCCESS });
			// dispatch(showMessage({ message: res.data.message }));
			window.location.reload();
		}
	} catch (err) {
		dispatch(showMessage({ message: err.message }));
	}
};
