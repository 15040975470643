import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'applications',
		title: 'Applications',
		translate: 'APPLICATIONS',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				translate: 'Dashboard',
				type: 'item',
				icon: 'dashboard',
				url: '/optir/dashboard',
				exact: true
			},
			{
				id: 'users-management',
				title: 'Users Management',
				translate: 'Users Management',
				type: 'item',
				icon: 'group',
				url: '/optir/dashboard/users',
				exact: true
			},
			{
				id: 'subscription-management',
				title: 'Subscription Management',
				translate: 'Subscription Management',
				type: 'item',
				icon: 'done_all',
				url: '/optir/dashboard/subscription-management',
				exact: true
			},
			{
				id: 'refund-management',
				title: 'Refund Management',
				translate: 'Refund Management',
				type: 'item',
				icon: 'cached',
				url: '/optir/dashboard/refund-management',
				exact: true
			},
			{
				id: 'credentials-management',
				title: 'Credentials Management',
				translate: 'Credentials Management',
				type: 'item',
				icon: 'lock_open',
				url: '/optir/dashboard/credential-management/twilio',
				exact: true
			},
			{
				id: 'sovren-management',
				title: 'Sovren Management',
				translate: 'Sovren Management',
				type: 'item',
				icon: 'settings',
				url: '/optir/dashboard/sovren-management',
				exact: true
			},
			{
				id: 'coupon-management',
				title: 'Coupon Management',
				translate: 'Coupon Management',
				type: 'item',
				icon: 'key',
				url: '/optir/dashboard/dicount-coupon',
				exact: true
			},
			{
				id: 'cover-management',
				title: 'Cover Management',
				translate: 'Cover Management',
				type: 'item',
				icon: 'note',
				url: '/optir/dashboard/cover-management',
				exact: true
			},
			{
				id: 'count-management',
				title: 'Count Management',
				translate: 'Count Management',
				type: 'item',
				icon: 'view_list',
				url: '/optir/dashboard/count-management',
				exact: true
			},
			{
				id: 'words-to-avoid',
				title: 'Words To Avoid',
				translate: 'Words To Avoid',
				type: 'item',
				icon: 'spellcheck',
				url: '/optir/dashboard/words-to-avoid',
				exact: true
			},
			{
				id: 'default-files',
				title: 'Default Files',
				translate: 'Default Files',
				type: 'item',
				icon: 'file_copy',
				url: '/optir/dashboard/default-files',
				exact: true
			},
			{
				id: 'videos-links',
				title: 'Video Links',
				translate: 'Video Links',
				type: 'item',
				icon: 'video_library',
				url: '/optir/dashboard/videos-apis',
				exact: true
			},
			{
				id: 'version-management',
				title: 'Version Management',
				translate: 'Version Management',
				type: 'item',
				icon: 'update',
				url: '/optir/dashboard/version-management',
				exact: true
			},
			{
				id: 'top-soft-skills',
				title: 'Top Soft Skills',
				translate: 'Top Soft Skills',
				type: 'item',
				icon: 'list_alt',
				url: '/optir/dashboard/top-soft-skills',
				exact: true
			},
			{
				id: 'email-templates',
				title: 'Email Templates',
				translate: 'Email Templates',
				type: 'item',
				icon: 'all_inbox',
				url: '/optir/dashboard/email-templates',
				exact: true
			},
			{
				id: 'admin-management',
				title: 'Admin Management',
				translate: 'Admin Management',
				type: 'item',
				icon: 'group',
				url: '/optir/dashboard/admin-management',
				exact: true
			},
			{
				id: 'temp-email-management',
				title: 'Temp Email Management',
				translate: 'Temp Email Management',
				type: 'item',
				icon: 'alternate_email',
				url: '/optir/dashboard/temp-mail-management',
				exact: true
			},
			{
				id: 'blocked-users',
				title: 'Blocked Users',
				translate: 'Blocked Users',
				type: 'item',
				icon: 'block',
				url: '/optir/dashboard/blocked-users',
				exact: true
			},
			{
				id: 'json-uploader',
				title: 'JSON Uploader',
				translate: 'JSON Uploader',
				type: 'item',
				icon: 'backup',
				url: '/optir/dashboard/json-uploader',
				exact: true
			},
			{
				id: 'notification-handler',
				title: 'Notification Handler',
				translate: 'Notification Handler',
				type: 'item',
				icon: 'notifications	',
				url: '/optir/dashboard/notification-handler',
				exact: true
			}
		]
	}
];

export default navigationConfig;
