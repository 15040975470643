import Axios from 'axios';
import { url } from '../../../endpoint';
import { showMessage } from './message.actions';

export const getJson = () => async dispatch => {
	try {
		let res = await Axios.get(`${url}/admin/get-temp-mail`);

		if (res.data.status === 200) {
			dispatch({
				type: 'GET_TEMP_MAIL',
				payload: res.data.data
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const updateJson = data => async dispatch => {
	try {
		let res = await Axios.post(`${url}/admin/update-temp-mail`, {
			data
		});

		if (res.data.status === 200) {
			dispatch({
				type: 'UPDATE_TEMP_MAIL',
				payload: data
			});
		}
	} catch (error) {
		dispatch(showMessage({ message: error.message }));
	}
};
export const addJson = data => async dispatch => {
	try {
		let res = await Axios.post(`${url}/admin/create-temp-mail`, {
			email: {
				email: data.email
			}
		});

		if (res.data.status === 200) {
			dispatch({
				type: 'ADD_TEMP_MAIL',
				payload: data
			});
		}
	} catch (error) {
		dispatch(showMessage({ message: error.message }));
	}
};

export const uploadJson = (data, loader) => async dispatch => {
	try {
		loader(true);
		const formData = new FormData();
		formData.append('jsonFile', data);

		const res = await Axios.post(`${url}/admin/add-json-suggestions`, formData);
		console.log('response', res);
		if (res.data.status === 200) {
			console.log('successfully added');
		}
		loader(false);
	} catch (error) {
		loader(false);
		dispatch(showMessage({ message: error.message }));
	}
};

export const deleteJson = oid => async dispatch => {
	try {
		let res = await Axios.post(`${url}/admin/delete-temp-mail`, {
			oid
		});

		if (res.data.status === 200) {
			dispatch({
				type: 'DELETE_TEMP_MAIL',
				payload: oid
			});
		}
	} catch (error) {
		dispatch(showMessage({ message: error.message }));
	}
};
