

const initialState = {
    emails: []
}


    

const tempMailReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TEMP_MAIL": {
            return {
                emails: action.payload
            };
        }
        case "ADD_TEMP_MAIL":{
        
            return {
                emails: [...state.emails, action.payload]
            };

        }
        case "UPDATE_TEMP_MAIL": {
            return {
            emails: state.emails.map((item) => {
                if (item.oid === action.payload.oid) {
                    item.email = action.payload.email;
                }
                return item;
            })
            
            };
        }
        case "DELETE_TEMP_MAIL": {
            return {
                emails: state.emails.filter((item) => item.oid !== action.payload)
            };
        }

        default: {
            return state;
        }
    }
};

export default tempMailReducer;