import React from 'react';
import Main from './Main';
import 'toastr/build/toastr.min.css';
import 'toastr/build/toastr.min.js';

const App = () => {
	return <Main />;
};

export default App;
