import React, { Component } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

export default class From extends Component {
	state = {
		selectedDate: new Date()
	};

	componentDidUpdate() {
		const { selectedDate } = this.state;
		this.props.setFrom(selectedDate);
	}

	handleDateChange = date => {
		this.setState({ selectedDate: date });
		this.props.setFrom(date);
	};

	render() {
		const { selectedDate } = this.state;

		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<div key="datetime_default" className="picker">
					<DateTimePicker
						fullWidth
						value={selectedDate}
						showTabs={false}
						onChange={this.handleDateChange}
						leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
						rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
					/>
				</div>
			</MuiPickersUtilsProvider>
		);
	}
}
