// import FuseUtils from '@fuse/utils';
import { showMessage } from 'app/store/actions/fuse';
import UserManagementService from 'app/services/userManagementService';
// FuseUtils.generateGUID()
// dispatch(showMessage({ message: 'Product Saved' }));
import { url } from '../../../endpoint';
import axios from 'axios';

export const GET_USERS = 'GET_USERS';
export const TOTAL_USERS_LENGTH = 'TOTAL_USERS_LENGTH';
export const TODAYS_USERS_LENGTH = 'TODAYS_USERS_LENGTH';
export const GET_ADMINS = 'GET_ADMINS';

export const getUsersAction = (page, rowsPerPage) => async dispatch => {
	// console.log('came here', page, rowsPerPage);
	try {
		dispatch({
			type: 'GET_USER_REQUEST'
		});
		const res = await UserManagementService.getUsers(page, rowsPerPage);
		if (res) {
			// console.log('res', res);
			dispatch({
				type: GET_USERS,
				payload: res.data.data
			});
			dispatch({
				type: TOTAL_USERS_LENGTH,
				payload: res.data.total
			});
			dispatch({
				type: TODAYS_USERS_LENGTH,
				payload: res.data.todayUsers
			});
		} else {
			dispatch({
				type: 'GET_USER_FAILED'
			});
		}
	} catch (err) {
		dispatch({
			type: 'GET_USER_FAILED'
		});
		console.log('err', err);
	}
};
export const getBlockUsersAction = (page, rowsPerPage) => async dispatch => {
	// console.log('came here', page, rowsPerPage);
	try {
		dispatch({
			type: 'GET_USER_REQUEST'
		});
		const res = await UserManagementService.getBlockUsers(page, rowsPerPage);
		if (res) {
			// console.log('res', res);
			dispatch({
				type: GET_USERS,
				payload: res.data.data
			});
			dispatch({
				type: TOTAL_USERS_LENGTH,
				payload: res.data.total
			});
			dispatch({
				type: TODAYS_USERS_LENGTH,
				payload: res.data.todayUsers
			});
		} else {
			dispatch({
				type: 'GET_USER_FAILED'
			});
		}
	} catch (err) {
		dispatch({
			type: 'GET_USER_FAILED'
		});
		console.log('err', err);
	}
};
export const getAdminAction = (page, rowsPerPage) => async dispatch => {
	// console.log('came here', page, rowsPerPage);
	try {
		dispatch({
			type: 'GET_ADMIN_REQUEST'
		});
		const res = await UserManagementService.getAdmin(page, rowsPerPage);
		if (res) {
			console.log('res', res.data.data);
			dispatch({
				type: GET_ADMINS,
				payload: res.data.data
			});
			dispatch({
				type: TOTAL_USERS_LENGTH,
				payload: res.data.total
			});
			dispatch({
				type: TODAYS_USERS_LENGTH,
				payload: res.data.todayUsers
			});
		} else {
			dispatch({
				type: 'GET_ADMIN_FAILED'
			});
		}
	} catch (err) {
		dispatch({
			type: 'GET_ADMIN_FAILED'
		});
		console.log('err', err);
	}
};

export const updateStatusAction = (status, id, credit) => async dispatch => {
	try {
		let data = {
			status,
			id,
			credit
		};
		const res = await UserManagementService.updateUser(data);
		if (res.status === 200) {
			console.log(res);
			dispatch(showMessage({ message: 'status updated' }));
		}
	} catch (err) {
		console.log('err', err);
	}
};
export const deleteAdminAction = (oid, history) => async dispatch => {
	try {
		let data = {
			oid
		};
		const res = await UserManagementService.deleteAdmin(data);
		if (res.status === 200) {
			history.push('/optir/dashboard/admin-management');
			dispatch(showMessage({ message: 'Admin Deleted' }));
		}
	} catch (err) {
		console.log('err', err);
	}
};
export const updateAdminAction = (oid, email, password, permissions, role, history, name) => async dispatch => {
	try {
		let data = {
			oid,
			email,
			password,
			name,
			permissions,
			role
		};
		const res = await UserManagementService.updateAdmin(data);
		if (res.status === 200) {
			history.push('/optir/dashboard/admin-management');
			dispatch(showMessage({ message: 'Admin updated' }));
		}
	} catch (err) {
		console.log('err', err);
	}
};
export const AddAdminAction = (email, password, role, permissions, name) => async dispatch => {
	try {
		let data = {
			email,
			password,
			role,
			name,
			permissions,
			registeredAt: new Date()
		};
		const res = await UserManagementService.AddAdmin(data);
		if (res.status === 200) {
			console.log(res);
			dispatch(showMessage({ message: 'Admin Added' }));
		}
	} catch (err) {
		console.log('err', err);
	}
};

export const refundUserPayment = (id, cb) => async dispatch => {
	try {
		const data = {
			id
		};
		const res = await UserManagementService.refundUserPayment(data);
		if (res.status === 200) {
			cb(res?.data);
			dispatch(showMessage({ message: res?.data?.message || 'Payment Refunded' }));
			// dispatch(showMessage({ message: 'status updated' }));
		}
	} catch (err) {
		cb(false);
		console.log('err', err);
		dispatch(showMessage({ message: 'Something wrong while Refunding' }));
	}
};

export const getUserSubsriptionAction = (id, cb) => async dispatch => {
	try {
		const data = {
			id
		};
		const res = await UserManagementService.getUserSubsription(data);
		if (res.status === 200) {
			cb(res?.data);
			// dispatch(showMessage({ message: 'status updated' }));
		}
	} catch (err) {
		cb(false);
		console.log('err', err);
	}
};

export const getUserRefund = (payment_intent, cb) => async dispatch => {
	try {
		const data = {
			payment_intent
		};
		const res = await axios.post(`${url}/admin/get-user-refund`, data, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		if (res.status === 200) {
			cb(res?.data);
			// dispatch(showMessage({ message: 'status updated' }));
		}
	} catch (err) {
		cb(false);
		console.log('err', err);
	}
};

export const getScoreCounts = () => async dispatch => {
	try {
		dispatch({
			type: 'AVOID_WORDS_FETCH_START'
		});
		const res = await UserManagementService.CountScore();
		if (res.data.status === 200) {
			dispatch({
				type: 'SAVE_COUNT_SCORE',
				payload: res.data
			});
		}
	} catch (error) {
		console.log('err', error);
	}
};

export const getAvoidWords = () => async dispatch => {
	try {
		dispatch({
			type: 'AVOID_WORDS_FETCH_START'
		});
		const res = await UserManagementService.AvoidWords();
		if (res.data.status === 200) {
			dispatch({
				type: 'SAVE_AVOID_WORDS',
				payload: res.data
			});
		}
	} catch (error) {
		dispatch({
			type: 'AVOID_WORDS_FAILED'
		});
		console.log('err', error);
	}
};

export const addAvoidWordsAction = (data, cb) => async dispatch => {
	try {
		dispatch({
			type: 'ADD_AVOID_REQUEST'
		});
		let res = await UserManagementService.AddAvoidWords(data);
		cb && cb();
		if (res.data.status === 200) {
			dispatch(showMessage({ message: res.data.messgage }));
			dispatch({
				type: 'ADDED_AVOID_WORDS'
			});
			const resp = await UserManagementService.AvoidWords();
			if (resp.data.status === 200) {
				dispatch({
					type: 'SAVE_AVOID_WORDS',
					payload: resp.data
				});
			}
		} else {
			dispatch(showMessage({ message: 'error' }));
			dispatch({
				type: 'ADD_AVOID_FAILED'
			});
		}
	} catch (error) {
		dispatch({
			type: 'ADD_AVOID_FAILED'
		});
		console.log(error);
	}
};

export const updateScoreAction = (data, cb) => async dispatch => {
	// console.log(data);
	try {
		dispatch({
			type: 'ADD_SKILLS_REQUEST'
		});
		let res = await UserManagementService.UpdateScore(data);
		// console.log(res);
		if (cb) {
			cb();
		}
		if (res.data.status === 200) {
			dispatch(showMessage({ message: res.data.messgage }));
		} else {
			dispatch(showMessage({ message: 'Something wrong while updating' }));
		}
	} catch (error) {
		if (cb) {
			cb();
		}
		console.log(error);
	}
};

export const addSkillsAction = (data, cb) => async dispatch => {
	try {
		dispatch({
			type: 'ADD_SKILLS_REQUEST'
		});
		let res = await UserManagementService.AddSoftSkills(data);
		if (res.data.status === 200) {
			if (cb) {
				cb(true);
			}
			dispatch(showMessage({ message: res.data.messgage }));
			dispatch({
				type: 'ADD_SKILLS_SUCCESS'
			});
			dispatch(getSkillsAction());
		} else {
			if (cb) {
				cb();
			}
			dispatch(showMessage({ message: 'error' }));
			dispatch({
				type: 'ADD_SKILLS_FAILED'
			});
		}
	} catch (error) {
		if (cb) {
			cb();
		}
		dispatch({
			type: 'ADD_SKILLS_FAILED'
		});
		console.log(error);
	}
};

export const getSkillsAction = () => async dispatch => {
	// console.log(data);
	try {
		dispatch({
			type: 'GET_SKILLS_START'
		});
		let res = await UserManagementService.getSoftSkills();
		if (res.data.status === 200) {
			dispatch({
				type: 'SAVE_SOFT_SKILLS',
				payload: res.data
			});
		}
	} catch (error) {
		dispatch({
			type: 'GET_SKILLS_FAILED'
		});
		console.log(error);
	}
};

export const fileParser = file => async dispatch => {
	const files = new FormData();
	files.append('file', file);

	try {
		const res = await axios.post(`${url}/upload-resume`, files);
		if (res.data.response) {
			dispatch({
				type: 'PARSE_FILE',
				payload: res.data.response
			});
		} else {
			dispatch(showMessage({ message: 'error' }));
		}
	} catch (error) {
		dispatch(showMessage({ message: error }));
	}
};
