import FuseUtils from '@fuse/utils';
import AppContext from 'app/AppContext';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
// import { getCurrentLoginStatusAction } from 'app/store/actions/fuse/auth.actions';

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props);
		const { routes } = context;
		this.state = {
			accessGranted: true,
			routes
		};
	}

	componentDidMount() {
		this.redirectRoute();
		// this.props.getCurrentLoginStatusAction();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.accessGranted !== this.state.accessGranted;
	}

	componentDidUpdate() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location, userRole,currentStatus } = props;
		const { pathname } = location;

		const matched = matchRoutes(state.routes, pathname)[0];
		
		return {
			accessGranted: matched ? FuseUtils.hasPermission(matched.route.auth, userRole) : true
		}

	}

	redirectRoute() {
		const { location, history, userRole, currentStatus } = this.props;
		const { pathname, state } = location;
		const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';
		if (!userRole || userRole.length === 0 || userRole[0] === '' || userRole[0] != 'admin' || !currentStatus) {
			history.push({
				pathname: '/login',
				state: { redirectUrl: pathname }
			});
		} else {
			history.push({
				pathname: '/optir/dashboard'
			});
		}
	}

	render() {
		console.log('<==========now came here=========>', this.props.currentStatus);
		return this.state.accessGranted ? <>{this.props.children}</> : null;
	}
}

const mapStateToProps = store => {
	return {
		userRole: store.fuse.loginReducer.role,
		currentStatus:store.fuse.loginReducer.userStatus
	};
};

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps, null)(FuseAuthorization));
