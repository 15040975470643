import {
	GET_SUBSCRIPTIONS,
	GET_REFUND_SUBSCRIPTIONS,
	GET_COVER_SUBSCRIPTIONS
} from '../../actions/fuse/subscription.action';

const initialState = {
	subscriptions: [],
	subDataStatus: false,
	refundsSubscription: [],
	refundStatus: false,
	covers: []
};

const subscriptionReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SUB_REFUND_GET_REQUEST':
			return {
				refundStatus: true
			};
		case 'SUB_REFUND_GET_FAILED':
			return {
				refundStatus: false
			};

		case GET_COVER_SUBSCRIPTIONS:
			return {
				...state,
				covers: action.payload,
				refundStatus: false
			};
		case GET_REFUND_SUBSCRIPTIONS:
			return {
				...state,
				refundsSubscription: action.payload,
				refundStatus: false
			};

		case 'SUB_GET_REQUEST':
			return {
				subDataStatus: true
			};
		case 'SUB_GET_FAILED':
			return {
				subDataStatus: false
			};

		case GET_SUBSCRIPTIONS:
			return {
				...state,
				subscriptions: action.payload,
				subDataStatus: false
			};
		default: {
			return { ...state };
		}
	}
};

export default subscriptionReducer;
