import { Box, Icon, IconButton, TextField, Typography, CircularProgress, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import moment from 'moment';
import React from 'react';
import useWindow from 'app/utils/useWindowSize';
import { FaAndroid, FaApple, FaGlobe, FaMobile } from 'react-icons/fa';
import toastr from 'toastr';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_VERSION_DATA, updateVersions } from 'app/store/actions/fuse/version.action';

const VersionEntry = ({ name, item }) => {
	const [isEditState, setIsEditState] = React.useState(false);
	const { deleteVersionData, deleteLoader } = useSelector(({ fuse }) => fuse.versionReducer);
	const [newVersion, setNewVersion] = React.useState(item?.version);
	const { width } = useWindow();
	const dispatch = useDispatch();
	const [updateLoader, setUpdateLoader] = React.useState(false);
	const updateHandler = () => {
		setUpdateLoader(true);
		const data = {
			version: newVersion,
			_id: item?._id,
			isCurrent: item?.isCurrent
		};
		dispatch(
			updateVersions(name, data, () => {
				setUpdateLoader(false);
				setIsEditState(!isEditState);
			})
		);
	};
	React.useEffect(() => {
		setNewVersion(item?.version);
	}, [item, isEditState]);

	return (
		<Box
			style={{
				display: isEditState ? 'block' : 'flex',
				justifyContent: 'space-between',
				boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
				padding: 10,
				borderRadius: 5,
				backgroundColor: item?.isCurrent ? '#e6ffe6' : '#fff'
			}}
		>
			{isEditState ? (
				<>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							gap: 20,
							backgroundColor: 'transparent'
						}}
					>
						<TextField
							fullWidth
							color="primary"
							label="Update Version"
							placeholder="e.g. 1.0.0"
							value={newVersion}
							required
							onChange={e => setNewVersion(e.target.value)}
						/>
						<div
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 10 }}
						>
							<IconButton
								onClick={() => {
									setIsEditState(!isEditState);
								}}
							>
								<Icon color="error">cancel</Icon>
							</IconButton>
							<IconButton
								onClick={() => {
									if (newVersion.length < 1) {
										toastr.error('Please enter a valid version number');
										return;
									}
									updateHandler(item);
								}}
							>
								{updateLoader ? (
									<CircularProgress color="primary" size={25} />
								) : (
									<Icon color="primary">save</Icon>
								)}
							</IconButton>
						</div>
					</Box>
				</>
			) : (
				<>
					<div style={{ width: '100%' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{width > 600 && (
									<>
										{name === 'web' && (
											<FaGlobe color={item?.isCurrent && 'green'} style={{ marginRight: 10 }} />
										)}
										{name === 'mobile' && (
											<FaMobile color={item?.isCurrent && 'green'} style={{ marginRight: 10 }} />
										)}
										<Typography
											style={{ color: item?.isCurrent && 'green', padding: 5 }}
											variant="subtitle1"
										>
											{name}
										</Typography>
										<p
											style={{ width: 1, height: 20, backgroundColor: '#000', margin: '0 10px' }}
										/>
									</>
								)}
								<Box
									style={{
										display: 'flex',
										color: '#000',
										flexDirection: 'column',
										alignItems: 'start',
										marginLeft: 5
									}}
								>
									<Typography variant="body2" style={{ padding: 2 }}>
										Updated on
									</Typography>
									<Typography variant="body1" style={{ padding: 2 }}>
										{moment(item?.updatedAt).format('ll')} at{' '}
										{moment(item?.updatedAt).format('hh:mm A')}
									</Typography>
								</Box>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Box
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'start',
										padding: '0 22px'
									}}
								>
									{/* <Box style={{ marginRight: 10 }}>
									</Box> */}
									<Typography
										style={{
											color: item?.isCurrent ? 'green' : 'black',
											padding: 5
										}}
										variant="body1"
									>
										{item?.isCurrent ? 'Current Version' : 'Old Version'}
									</Typography>

									<Typography variant="body1">{item?.version}</Typography>
								</Box>

								{!item?.isCurrent && (
									<IconButton
										onClick={() => {
											dispatch({
												type: DELETE_VERSION_DATA,
												payload: {
													isDeleteState: true,
													name,
													version: item.version,
													_id: item?._id
												}
											});
										}}
									>
										<Icon color="error">delete</Icon>
									</IconButton>
								)}
								<IconButton
									onClick={() => {
										setIsEditState(!isEditState);
									}}
								>
									<Icon color="primary">edit</Icon>
								</IconButton>
							</div>
						</div>
						<div>
							<Typography variant="body1" style={{ paddingTop: 7 }}>
								{item?.description}
							</Typography>
						</div>
					</div>
				</>
			)}
		</Box>
	);
};

export default VersionEntry;
