import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fileParser, getDefaultFiles, updateDefaultFiles } from "app/store/actions/fuse/default-file-action";

export default function useDefaultFile() {
    const dispatch = useDispatch();
    const text = useSelector(state => state.fuse.defaultFileReducer);
    const [state, setState] = useState({
        resume: text.resume,
        jobDescription: text.jobDescription,
        company: text.company,
        jobTitle: text.jobTitle,
    });
    const [files, setFiles] = useState({
        resume: "",
        jobDescription: ""
    });
    const [loading, setLoading] = useState({
        resume: false,
        jobDescription: false
    });

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch(getDefaultFiles());
    }, [])

    useEffect(() => {
        setState({
            resume: text.resume,
            jobDescription: text.jobDescription,
            company: text.company,
            jobTitle: text.jobTitle,
        });
    }, [text])

    const closeLoader = () => {
        setLoading({
            resume: false,
            jobDescription: false
        });
    }

    const cb = (key, value) => {
        handleStateChange(key, value);
        closeLoader();
    }

    const handleStateChange = (key, value) => {
        setState({
            ...state,
            [key]: value,
        });
    };

    const handleFileChange = (key, value) => {
        setFiles({
            ...files,
            [key]: value,
        });
        setLoading({
            ...loading,
            [key]: true
        });
        dispatch(fileParser(value, cb, key, closeLoader));
    };

    const updateHandler = () => {
        dispatch(updateDefaultFiles(state, files, setLoader));
    }

    return {
        state,
        loader,
        loading,
        handleStateChange,
        handleFileChange,
        files,
        updateHandler
    }
}