import FusePageSimple from '@fuse/core/FusePageSimple';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React, { useEffect,useState } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import './css.css';
import { getSkillsAction } from '../../store/actions/fuse/usersActon';
import Table from './usersTable';
import FuseSplashScreen from '@fuse/core/fuseSplash2';
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import EmptyScreen from './empty'
import { useDispatch, useSelector } from 'react-redux'
const data = [0]
function CountManagemets(props) {
	const dispatch = useDispatch()
	const [counts,setCounts] = useState({})
	const { softSkills,getSkillsStatus } = useSelector(store => {
		return {
			softSkills: store.fuse.usersReducer.softSkills,
			getSkillsStatus: store.fuse.usersReducer.getSkillsStatus
		};
	})
	useEffect(() => {
		dispatch(getSkillsAction())
	}, [])

	useEffect(()=> {
		if(softSkills["sovren_counts"]){
			setCounts(softSkills?.sovren_counts)
		}
	},[softSkills?.sovren_counts])


	const handleFile = e => {
		let fileObj = e.target.files[0];

		// console.log(e.target.files[0].type.split('application/vnd.openxmlformats-officedocument.spreadsheetml.')[1]);
		// if (['pdf', 'docx', 'xlsx'].includes(e.target.files[0].type.split('/')[1])) {
			// props.fileParser(e.target.files[0]);
		// } else {
			// toastr.error('please upload pdf and docx only');
		// }
		ExcelRenderer(fileObj, (err, resp) => {
			if (err) {
			  console.log(err);
			} else {
				const sovren_counts = {}
				resp.rows.map(item=>{
					if(item[0]){
						sovren_counts[item[0]] = {disc:item[3],show:item[6],category:item[4]}
					}
				})

				delete sovren_counts["Code"]
				setCounts(sovren_counts)
			//   setCols(resp.cols)
			//   setRows(resp.rows);
			}
		  });
	};
	
	return (
		<>

			<FusePageSimple
				header={
					<div className="flex flex-1 items-center justify-between p-24">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">settings</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography variant="h6" className="mx-12 hidden sm:flex">
									Sovren Management
								</Typography>
								
							</FuseAnimate>

							<span class="image-upload">
							<label
								for="file-input"
								style={{
									fontWeight: '600',
									color: 'white',
									cursor: 'pointer',
									fontSize: '16px',
									margin: 0,
									paddingLeft: 8,
									paddingRight: 8
								}}
							>
								Upload Sovren File
							</label>

							<input id="file-input" type="file" onChange={handleFile} />
						</span>{' '}
						</div>
					</div>
				}
				content={
					getSkillsStatus ? (
						<FuseSplashScreen />
					) : (
							<div className="p-24 max-w-xl">
								{softSkills && counts ? (
									<Table oid={softSkills?.oid} sovren_counts={counts}/>
								) : <EmptyScreen />}
							</div>
						)
				}
			/>
		</>
	);
}
export default CountManagemets
