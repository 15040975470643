import Axios from 'axios';
import { url } from '../../../endpoint';
import { showMessage } from './message.actions';

export const GET_EMAIL_TEMPLATES = '[EMAIL TEMPLATE APP] GET EMAIL TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE = '[EMAIL TEMPLATE APP] UPDATE EMAIL TEMPLATE';

export const getEmailTemplates = () => async (dispatch) => {
    try {
        let res = await Axios.get(`${url}/admin/get-email-templates`);
        if (res.data.status === 200) {
            dispatch({
                type: GET_EMAIL_TEMPLATES,
                payload: res.data.data.emailTemplates,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const updateEmailTemplate = (templates, cb) => async (dispatch) => {
    try {
        let res = await Axios.post(`${url}/admin/update-email-templates`, templates);
        if (res.data.status === 200) {
            dispatch(showMessage({ message: 'Email template updated successfully.', variant: 'success' }));
            dispatch({
                type: UPDATE_EMAIL_TEMPLATE,
                payload: templates,
            })
        }
        cb();
    }
    catch (error) {
        console.log(error);
        cb();
    }
}