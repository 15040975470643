import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import { url } from '../../endpoint';
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
	signInWithEmailAndPassword = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/login`, data, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getCurrentLoginStatus = data => {
			// const headers = {
			// 	'Content-Type': 'application/json',
			// 	'Authorization': 'Bearer ' + data
			// };
			axios.defaults.headers.common['Authorization'] = `Bearer ${data}`;
			return new Promise((resolve, reject) => {
				axios
					.get(`${url}/admin/get-admin-details`)
					.then(resolve)
					.catch(reject);
			});
	};

	logoutService = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/logout`, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};
}

const instance = new JwtService();

export default instance;
