import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import { addSkillsAction } from '../../store/actions/fuse/usersActon';
import { useDispatch } from 'react-redux';

const default_counts_managements = {
	certs: 10,
	core_skills: 60,
	education: 20,
	mgmt_level: 0,
	min_years_exp: 0,
	oid: '5fb53dede6114cbd72a94fa4',
	soft_skills: 5,
	title: 5
};

function UsersTable({ sovren_counts = {}, oid = '' }) {
	const [loader, setLoader] = useState(false);
	const dispatch = useDispatch();
	// useEffect(()=> {
	// var counts_managements_final = counts_managements
	// delete counts_managements_final["oid"]

	// },[])

	console.log('counts_managements', oid);
	const stopLoader = () => {
		setLoader(false);
	};
	const onUpdate = () => {
		if (!loader) {
			setLoader(true);
			dispatch(addSkillsAction({ sovren_counts, oid: oid }, stopLoader));
		}
	};

	return (
		<div className="w-full flex flex-col">
			{Object.keys(sovren_counts).map(n => {
				return (
					<>
						<Typography style={{ paddingBottom: 5 }} variant="h2" id="discrete-slider-always" gutterBottom>
							{n} - {sovren_counts[n]['show'] ? 'Yes' : 'No'}
						</Typography>
						<Typography
							style={{ paddingBottom: 30 }}
							id="discrete-slider-always"
							variant="h4"
							gutterBottom
						>
							Category - {sovren_counts[n]?.category}
						</Typography>
						<Typography
							style={{ paddingBottom: 30 }}
							id="discrete-slider-always"
							variant="body"
							gutterBottom
						>
							{sovren_counts[n]['disc']}
						</Typography>
					</>
				);
			})}

			<Button variant="contained" color="primary" onClick={onUpdate} style={{ width: 112, float: 'right' }}>
				{loader ? 'loading' : 'update'}
			</Button>
		</div>
	);
}

export default UsersTable;
