import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import LoginConfig from 'app/main/login/LoginConfig';
import AppConfig from 'app/fuse-configs/appsConfigs';
const routeConfigs = [LoginConfig, ...AppConfig];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		exact:true,
		component: () => <Redirect to="/login" />
	}
];

export default routes;
