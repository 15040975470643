import authRoles from '../../auth/authRoles';
import TempMailManagement from './TempMailManagement';

const tempMailManagementConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/temp-mail-management',
			component: TempMailManagement
		}
	]
};

export default tempMailManagementConfig;
