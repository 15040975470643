import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import { url } from '../../endpoint';
/* eslint-disable camelcase */

class SubscriptionService extends FuseUtils.EventEmitter {
	getSubscriptions = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-subscription`, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getRefundSubscriptions = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-all-refunds`, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	getCoverManagements = () => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.get(`${url}/admin/get-all-covers`, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	saveCoverLetter = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/add-cover-letter`, data, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};

	updateCoverLetter = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/update-cover-letter`, data, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};
	deleteCoverLetter = data => {
		const headers = {
			'Content-Type': 'application/json'
		};
		return new Promise((resolve, reject) => {
			axios
				.post(`${url}/admin/delete-cover-letter`, data, {
					headers: headers
				})
				.then(resolve)
				.catch(reject);
		});
	};
	
}

const instance = new SubscriptionService();

export default instance;
