import { InputBase, Paper, Typography } from '@material-ui/core';
import React from 'react';

export default function InputButton(props) {
    const { value = '', label = '' } = props;

    return (
        <Paper component="form" style={styles.paperField}>
            <Typography variant="h6">
                {label}
            </Typography>
            <div style={styles.fieldDiv}>
                <InputBase
                    value={value}
                    style={{ flex: 1 }}
                    inputProps={{ 'aria-label': 'input field' }}
                    onChange={(e) => props.onChange(label, e.target.value)}
                />
            </div>
        </Paper>
    )
}

const styles = {
    paperField: {
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    fieldDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconContainer: {
        padding: 10
    }
}
