import React, { memo } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Button, CircularProgress, Divider, Icon, InputBase, Paper, Typography } from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { styles } from './styles';
import useDefaultFile from './useDefaultFile';
import { FolderOpen } from '@material-ui/icons';

const DefaultFile = () => {
    const {
        state,
        loader,
        loading,
        handleStateChange,
        handleFileChange,
        files,
        updateHandler

    } = useDefaultFile();

    return (
        <FusePageSimple
            header={
                <div className="flex flex-1 items-center p-24">
                    <div className="flex items-center styleButton">
                        <FuseAnimate animation="transition.expandIn" delay={300}>
                            <Icon className="text-32">file_copy</Icon>
                        </FuseAnimate>
                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                            <Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
                                Default Files
                            </Typography>
                        </FuseAnimate>
                    </div>
                </div>
            }
            content={
                <div style={styles.contentContainer}>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                        <div style={styles.ButtonDiv}>
                            {
                                loader ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled
                                        style={styles.button}
                                    >
                                        Updating...
                                    </Button> :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={styles.button}
                                        onClick={updateHandler}
                                    >
                                        Update
                                    </Button>
                            }
                        </div>
                    </FuseAnimate>
                    <div>
                        <div style={styles.inputContainer}>
                            <div style={styles.inputDiv}>
                                <center>
                                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                        <Typography variant="h6">
                                            Job Description
                                        </Typography>
                                    </FuseAnimate>
                                </center>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '10px'
                                }}>
                                    <Paper component="form" style={{ width: '49%' }}>
                                        <InputBase
                                            value={state.company}
                                            style={{ height: 40, width: "100%", paddingLeft: 5 }}
                                            placeholder="Update Company Name"
                                            inputProps={{ 'aria-label': 'Update Company Name' }}
                                            onChange={(e) => handleStateChange('company', e.target.value)}
                                        />
                                    </Paper>
                                    <Paper component="form" style={{ width: '49%' }}>
                                        <InputBase
                                            value={state.jobTitle}
                                            style={{ height: 40, width: "100%", paddingLeft: 5 }}
                                            placeholder="Update Job Title"
                                            inputProps={{ 'aria-label': 'Update Job Title' }}
                                            onChange={(e) => handleStateChange('jobTitle', e.target.value)}
                                        />
                                    </Paper>
                                </div>
                                <center>
                                    <Paper component="form" style={styles.paperField}>
                                        <InputBase
                                            value={files.jobDescription && files.jobDescription.name}
                                            style={{ flex: 1 }}
                                            placeholder="Upload Job"
                                            readOnly
                                            inputProps={{ 'aria-label': 'Upload Job Description File' }}
                                        />
                                        <Divider style={styles.divider} orientation="vertical" />
                                        <input id='job-Input' type="file" style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange('jobDescription', e.target.files[0])}
                                        />
                                        {
                                            loading.jobDescription ?
                                                <CircularProgress size={40} style={{ padding: 10 }} /> :
                                                <label for="job-Input" style={styles.iconContainer}>
                                                    <FolderOpen />
                                                </label>
                                        }
                                    </Paper>
                                </center>
                                <textarea
                                    style={styles.textarea}
                                    value={state.jobDescription}
                                    onChange={(e) => handleStateChange('jobDescription', e.target.value)}
                                />
                            </div>
                            <div style={styles.inputDiv}>
                                <center>
                                    <Typography variant="h6">
                                        Resume
                                    </Typography>
                                </center>
                                <center>
                                    <Paper component="form" style={{ ...styles.paperField, marginTop: '50px' }}>
                                        <InputBase
                                            value={files.resume && files.resume.name}
                                            style={{ flex: 1 }}
                                            placeholder="Upload Resume"
                                            readOnly
                                            inputProps={{ 'aria-label': 'Upload Resume File' }}
                                        />
                                        <Divider style={styles.divider} orientation="vertical" />
                                        <input id='resume-Input' type="file" style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange('resume', e.target.files[0])}
                                        />
                                        {
                                            loading.resume ?
                                                <CircularProgress size={40} style={{ padding: 10 }} /> :
                                                <label for="resume-Input" style={styles.iconContainer}>
                                                    <FolderOpen />
                                                </label>
                                        }
                                    </Paper>
                                </center>
                                <textarea
                                    style={styles.textarea}
                                    value={state.resume}
                                    onChange={(e) => handleStateChange('resume', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default memo(DefaultFile)