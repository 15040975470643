// import React, { memo } from 'react';
// import FusePageSimple from '@fuse/core/FusePageSimple';
// import { Button, CircularProgress, Divider, Icon, InputBase, Paper, Typography } from '@material-ui/core';
// import FuseAnimate from '@fuse/core/FuseAnimate';
// import { FolderOpen } from '@material-ui/icons';
// import { styles } from './styles';
// import useNotificationHandler from './useNotificationHandler';

// const NotificationHandler = () => {
// 	const {
// 		emails,
// 		state,
// 		loader,
// 		setState,
// 		email,
// 		setEmail,
// 		addEmail,
// 		deleteMail,
// 		updateMailValue,
// 		isUpdate,
// 		updateMail,
// 		renderKey,
// 		error,
// 		isUpload,
// 		setIsUpload,
// 		useNotificationHandler,
// 		tempInput
// 	} = useJsonUploader();

// 	return (
// 		<FusePageSimple
// 			header={
// 				<div className="flex flex-1 items-center p-24">
// 					<div className="flex items-center styleButton">
// 						<FuseAnimate animation="transition.expandIn" delay={300}>
// 							<Icon className="text-32">cloud_upload</Icon>
// 						</FuseAnimate>
// 						<FuseAnimate animation="transition.slideLeftIn" delay={300}>
// 							<Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
// 								Json Uploader
// 							</Typography>
// 						</FuseAnimate>
// 					</div>
// 				</div>
// 			}
// 			content={
// 				<div style={styles.contentContainer}>
// 					<FuseAnimate animation="transition.slideLeftIn" delay={300}>
// 						<div style={styles.addContainer}>
// 							<div style={styles.ButtonDiv}>
// 								<Button
// 									disabled={isUpload}
// 									variant="contained"
// 									color="primary"
// 									style={styles.button}
// 									onClick={() => {
// 										document.getElementById('tempMail').click();
// 									}}
// 								>
// 									<Icon className="text-32">cloud_upload</Icon>
// 									&nbsp;
// 									{isUpload ? 'Uploading...' : 'Upload'}
// 								</Button>
// 								<input
// 									type="file"
// 									name="tempMail"
// 									ref={tempInput}
// 									id="tempMail"
// 									style={{ display: 'none' }}
// 									onChange={e => {
// 										const data = e.target.files[0];
// 										useNotificationHandler(data);
// 									}}
// 								/>
// 							</div>
// 						</div>
// 					</FuseAnimate>
// 					<div>
// 						<div style={styles.heading}>
// 							<Typography variant="h6" style={styles.headingText}>
// 								JSON uploaded file
// 							</Typography>

// 							{emails?.map((item, index) => {
// 								return (
// 									<div style={styles.mailContainer}>
// 										<Typography variant="h6" style={styles.mailText}>
// 											{item.email}
// 										</Typography>
// 										<div style={styles.mailButtonDiv}>
// 											<Icon
// 												style={styles.editIcon}
// 												onClick={() => {
// 													updateMailValue(item.oid, item.email);
// 												}}
// 												className="edit-22"
// 												style={styles.deleteIcon}
// 											>
// 												edit
// 											</Icon>
// 											<Icon
// 												onClick={() => {
// 													deleteMail(item.oid);
// 												}}
// 												className="text-22"
// 												style={styles.deleteIcon}
// 											>
// 												delete
// 											</Icon>
// 										</div>
// 									</div>
// 								);
// 							})}
// 						</div>
// 					</div>
// 				</div>
// 			}
// 		/>
// 	);
// };

// export default memo(NotificationHandler);

import FuseAnimate from '@fuse/core/FuseAnimate/FuseAnimate';
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	makeStyles
} from '@material-ui/core';
import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
	getNotifications,
	addNotification,
	updateNotification,
	deleteNotification
} from '../../store/actions/fuse/notification-action';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: 50,
		overflowY: 'auto',
		[theme.breakpoints.down('sm')]: {
			padding: 20
		}
	},
	input: {
		width: '100%'
	},
	text: {
		fontSize: 20,
		fontWeight: 600,
		marginBottom: 20
	},
	inputDiv: {
		marginTop: 20,
		display: 'flex',
		justifyContent: 'flex-end'
	},
	notificationDiv: {
		marginTop: 10,
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		border: '1px solid lightgrey',
		borderRadius: 10,
		padding: '10px 10px 0px 10px'
	},
	title: {
		fontSize: 18,
		fontWeight: 600
	},
	description: {
		fontSize: 14,
		color: 'grey',
		fontWeight: 600,
		marginBottom: 10,
		wordBreak: 'break-word'
	},
	tag: {
		fontSize: 12,
		color: 'grey',
		fontWeight: 600,
		marginBottom: 20,
		float: 'right'
	},
	actionButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		marginTop: -10,
		gap: 1,
		float: 'right'
	},
	titleDescription: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		gap: 6
	}
}));
function NotificationHandler(props) {
	const styles = useStyles();
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [tags, setTags] = useState('');
	const [updated, setUpdated] = useState(false);
	const [updateId, setUpdateId] = useState('');

	const getNotification = async () => {
		await props?.getNotifications();
	};
	useEffect(() => {
		getNotification();
	}, []);

	const notificationAdd = async () => {
		if (title !== '' && description !== '' && tags !== '') {
			const data = {
				title,
				description,
				tags
			};
			await props.addNotification(data);
			setTitle('');
			setDescription('');
			setTags('');
			await props.getNotifications();
		}
	};
	const updateHandler = data => {
		setUpdateId(data.id);
		setUpdated(true);
		setTitle(data.title);
		setDescription(data.description);
		setTags(data.tags);
	};
	const notificationUpdate = async () => {
		const data = {
			oid: updateId,
			title,
			description,
			tags
		};
		await props.updateNotification(
			data,
			setUpdateId(''),
			setTitle(''),
			setDescription(''),
			setTags(''),
			setUpdated(false)
		);
		await props.getNotifications();
	};
	const notificationDelete = async id => {
		const data = {
			oid: id
		};
		await props.deleteNotification(data);
		await props.getNotifications();
	};
	return (
		<div className={styles.root}>
			<style jsx>
				{`
					.item-enter {
						opacity: 0;
						transform: translateY(50%);
					}

					.item-enter-active {
						opacity: 1;
						transform: translateY(0%);
						transition: opacity 500ms, transform 500ms;
					}

					.item-exit {
						opacity: 1;
						transform: translateY(0%);
					}

					.item-exit-active {
						opacity: 0;
						transform: translateY(50%);
						transition: opacity 500ms, transform 500ms;
					}
				`}
			</style>
			<p className={styles.text}>Notifications</p>
			<Grid container spacing={3}>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<div>
						<TextField
							className={styles.input}
							label="Title"
							variant="outlined"
							size="small"
							value={title}
							onChange={e => setTitle(e.target.value)}
						/>
					</div>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<TextField
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={tags}
						select
						label="tag"
						variant="outlined"
						className={styles.input}
						size="small"
						onChange={e => setTags(e.target.value)}
					>
						<MenuItem value="URGENT">URGENT</MenuItem>
						<MenuItem value="PRIORITY">PRIORITY</MenuItem>
						<MenuItem value="REMAINDER">REMAINDER</MenuItem>
						<MenuItem value="DEADLINE">DEADLINE</MenuItem>
					</TextField>
				</Grid>
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<div>
						<TextField
							className={styles.input}
							label="Description"
							variant="outlined"
							size="small"
							value={description}
							onChange={e => setDescription(e.target.value)}
						/>
					</div>
				</Grid>
			</Grid>
			<div className={styles.inputDiv}>
				<FuseAnimate style={{ marginLeft: 20 }}>
					<Button
						variant="contained"
						color="secondary"
						disabled={title === '' || description === '' || tags === ''}
						onClick={updated ? notificationUpdate : notificationAdd}
						style={{ marginLeft: 20 }}
					>
						{updated ? 'Update' : 'Add Admin'}
					</Button>
				</FuseAnimate>
			</div>
			<p
				className={styles.text}
				style={{
					marginTop: 20,
					marginBottom: 10
				}}
			>
				All Notifications
			</p>
			<TransitionGroup>
				{props?.notification &&
					props?.notification?.data?.reverse()?.map((item, index) => {
						return (
							<CSSTransition key={item.id} timeout={500} classNames="item">
								<div className={styles.notificationDiv}>
									<div className={styles.titleDescription}>
										<div className={styles.title}>
											{item.title.substring(0, 1).toUpperCase() + item.title.substring(1)}
										</div>
										<div className={styles.description}>{item.description}</div>
									</div>
									<div>
										<p
											style={{
												color:
													item.tags === 'URGENT'
														? 'red'
														: item.tags === 'PRIORITY'
														? 'orange'
														: item.tags === 'REMAINDER'
														? 'blue'
														: item.tags === 'DEADLINE'
														? 'green'
														: 'black'
											}}
											className={styles.tag}
										>
											{item.tags}
										</p>
										<div className={styles.actionButton}>
											<div>
												<IconButton
													onClick={() => updateHandler(item)}
													style={{
														padding: 10
													}}
												>
													<EditIcon
														style={{
															color: 'green'
														}}
													/>
												</IconButton>
											</div>
											<div>
												<IconButton
													onClick={() => notificationDelete(item.id)}
													style={{
														padding: 10
													}}
												>
													<DeleteIcon
														style={{
															color: 'red'
														}}
													/>
												</IconButton>
											</div>
										</div>
									</div>
								</div>
							</CSSTransition>
						);
					})}
			</TransitionGroup>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		notification: state.fuse.notificationReducer.notifications
	};
};
const mapStateToDispatch = {
	getNotifications,
	addNotification,
	updateNotification,
	deleteNotification
};

export default connect(mapStateToProps, mapStateToDispatch)(NotificationHandler);
