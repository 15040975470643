import FusePageSimple from '@fuse/core/FusePageSimple';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import './css.css';
import { getRefundSubscriptionAction } from 'app/store/actions/fuse/subscription.action';

import Table from './usersTable';
import FuseSplashScreen from '@fuse/core/fuseSplash2';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import EmptyScreen from './empty';
import { useDispatch, useSelector } from 'react-redux';
const data = [0];
function CountManagemets(props) {
	const dispatch = useDispatch();
	const [counts, setCounts] = useState({});
	const { refundStatus, refundsSubscription } = useSelector(store => {
		return {
			refundsSubscription: store.fuse.subscriptionReducer.refundsSubscription,
			refundStatus: store.fuse.subscriptionReducer.refundStatus
		};
	});
	useEffect(() => {
		dispatch(getRefundSubscriptionAction());
	}, []);

	return (
		<>
			<FusePageSimple
				header={
					<div className="flex flex-1 items-center justify-between p-24">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">cached</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography variant="h6" className="mx-12 hidden sm:flex">
									Refund Management
								</Typography>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					refundStatus ? (
						<FuseSplashScreen />
					) : (
						<div className="p-24 overflow-auto m-auto">
							{refundsSubscription?.data?.length ? (
								<Table data={refundsSubscription?.data || []} />
							) : (
								<EmptyScreen />
							)}
						</div>
					)
				}
			/>
		</>
	);
}
export default CountManagemets;
