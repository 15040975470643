import React from 'react';
import { Box, Button, CircularProgress, Fade, Modal } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';

const DeleteModal = ({
	modal,
	handleModal,
	deleteHandler,
	loader,
	subtitle,
	title = 'Are you sure, you want to delete?',
	textAlign = 'left'
}) => {
	const styles = {
		modalContainer: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			width: 450,
			bgcolor: 'background.paper',
			boxShadow: 24,
			p: '40px 20px 50px 20px'
		},
		cancelIcon: {
			position: 'absolute',
			top: 5,
			right: 7,
			color: 'red',
			cursor: 'Pointer'
		},
		modalText: {
			fontSize: '20px',
			textAlign
		},
		subtitleText: {
			fontSize: 16
		},
		deleteButton: {
			position: 'absolute',
			bottom: 12,
			right: 7
		}
	};
	return (
		<Modal open={modal}>
			<Fade in={modal}>
				<Box sx={styles.modalContainer}>
					<CancelIcon style={styles.cancelIcon} onClick={handleModal} />
					<div style={styles.modalText}>{title}</div>
					{subtitle && <div style={styles.subtitleText}>{subtitle}</div>}
					<Button color="primary" variant="contained" style={styles.deleteButton} onClick={deleteHandler}>
						{loader ? <CircularProgress color="#fff" size={24} /> : 'Delete'}
					</Button>
				</Box>
			</Fade>
		</Modal>
	);
};

export default DeleteModal;
