import CredentialService from 'app/services/credentialService';
import { showMessage } from 'app/store/actions/fuse';

export const GET_CREDENTIALS = 'GET_CREDENTIALS';

export const getCredentialAction = () => async dispatch => {
	try {
		const res = await CredentialService.getCredentials();
		console.log(res);
		if (res.data.status === 200) {
			dispatch({
				type: GET_CREDENTIALS,
				payload: res.data.data
			});
		} else {
			console.log('err');
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateCredentialsAction = data => async dispatch => {
	try {
		dispatch({
			type: 'UPDATE_CREDS_REQUEST'
		});
		const res = await CredentialService.updateCredentials(data);
		if (res.status === 200) {
			dispatch(showMessage({ message: 'Successfully updated!' }));
			dispatch({
				type: 'UPDATE_CREDS_SUCCESS'
			});
		} else {
			dispatch(showMessage({ message: 'error!' }));
			dispatch({
				type: 'UPDATE_CREDS_FAILED'
			});
		}
	} catch (err) {
		dispatch(showMessage({ message: 'something wrong!' }));
		dispatch({
			type: 'UPDATE_CREDS_FAILED'
		});
	}
};
