import React from 'react';
import authRoles from '../../auth/authRoles';
import SkillManagement from './coupon';

const CouponConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/skills-management',
			component: SkillManagement
		}
	]
};

export default CouponConfig;
