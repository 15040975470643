import React, { memo } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Icon, Typography, Dialog, TextField, Button } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { styles } from './styles';
import useVideos from './useVideos';
import VideoItem from './VideoItem';

const Videos = () => {
	const {
		sortedVideos,
		videoKeys,
		updateHandler,
		addVideoModal,
		toggleAddModal,
		addVideoId,
		addVideoKey,
		addVideoName,
		setAddVideoName,
		setAddVideoKey,
		setAddVideoId,
		addVideosHandler,
		addVideoLoader,
		deleteVideoHandler
	} = useVideos();

	return (
		<>
			<Dialog open={addVideoModal} onClose={toggleAddModal}>
				<div style={{ padding: 25, minWidth: 320 }}>
					<div>
						<FuseAnimate animation="transition.slideLeftIn" delay={300}>
							<Typography variant="h6">Add Video</Typography>
						</FuseAnimate>
					</div>
					<div
						style={{
							marginTop: '10px'
						}}
					>
						<TextField
							fullWidth
							color="primary"
							label="Video ID"
							placeholder="e.g. 630533349"
							value={addVideoId}
							required
							onChange={e => setAddVideoId(e.target.value)}
						/>
					</div>
					<div
						style={{
							marginTop: '10px'
						}}
					>
						<TextField
							fullWidth
							color="primary"
							label="Video Name"
							placeholder="e.g. Optimize Part-1"
							value={addVideoName}
							required
							onChange={e => setAddVideoName(e.target.value)}
						/>
					</div>
					<div
						style={{
							marginTop: '10px',
							marginBottom: '20px'
						}}
					>
						<TextField
							fullWidth
							color="primary"
							label="Video Key"
							placeholder="e.g. ScanPage"
							required
							value={addVideoKey}
							onChange={e => setAddVideoKey(e.target.value)}
						/>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<Button
							variant="contained"
							color="primary"
							onClick={toggleAddModal}
							style={{ backgroundColor: 'red' }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={addVideoLoader}
							style={{ marginLeft: '10px' }}
							onClick={addVideosHandler}
						>
							{addVideoLoader ? <Loader type="Bars" color="#FFFFFF" height={24} width={20} /> : 'Add'}
						</Button>
					</div>
				</div>
			</Dialog>
			<FusePageSimple
				header={
					<div className="flex flex-1 items-center p-24 justify-between">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">video_library</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
									Video Links
								</Typography>
							</FuseAnimate>
						</div>
						<div className="flex items-center">
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
									Add Video
								</Typography>
							</FuseAnimate>
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32 cursor-pointer" onClick={toggleAddModal}>
									playlist_add
								</Icon>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					<div style={styles.contentContainer}>
						{(Object.values(sortedVideos) ?? [])?.map((video, index) => (
							<div
								key={index}
								style={{
									width: '49%',
									margin: 'auto',
									padding: '20px'
								}}
							>
								<VideoItem
									video={{ ...video, key: videoKeys[index] }}
									onUpdate={updateHandler}
									onDelete={deleteVideoHandler}
								/>
							</div>
						))}
					</div>
				}
			/>
		</>
	);
};

export default memo(Videos);
