import { combineReducers } from 'redux';
import dialog from './dialog.reducer';
import message from './message.reducer';
import navbar from './navbar.reducer';
import navigation from './navigation.reducer';
import routes from './routes.reducer';
import settings from './settings.reducer';
import loginReducer from './login.reducer';
import usersReducer from './users.reducer';
import credentialsReducer from './credentials-reducer';
import subscriptionReducer from './subscription-reducer';
import couponReducer from './coupon-reducer';
import defaultFileReducer from './default-file-reducer';
import emailTemplateReducer from './email-template-reducer';
import videosReducer from './videos-reducer';
import tempMailReducer from './temp-mail-reducer';
import notificationReducer from './notification-reducer';
import versionReducer from './version-reducer';

const fuseReducers = combineReducers({
	navigation,
	settings,
	navbar,
	message,
	dialog,
	routes,
	loginReducer,
	usersReducer,
	credentialsReducer,
	subscriptionReducer,
	couponReducer,
	defaultFileReducer,
	emailTemplateReducer,
	videosReducer,
	tempMailReducer,
	notificationReducer,
	versionReducer
});

export default fuseReducers;
