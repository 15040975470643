import { CHANGE_STATE } from 'app/store/actions/fuse/default-file-action';

const initialState = {
    resume: '',
    jobDescription: ''
};

const defaultFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_STATE: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default defaultFileReducer;