import FusePageSimple from '@fuse/core/FusePageSimple';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import './css.css';
import { getSkillsAction } from '../../store/actions/fuse/usersActon';
import Table from './usersTable';
import FuseSplashScreen from '@fuse/core/fuseSplash2';
import EmptyScreen from './empty'
import { useDispatch, useSelector } from 'react-redux'
const data = [0]
function CountManagemets(props) {
	const dispatch = useDispatch()
	const { softSkills,getSkillsStatus } = useSelector(store => {
		return {
			softSkills: store.fuse.usersReducer.softSkills,
			getSkillsStatus: store.fuse.usersReducer.getSkillsStatus
		};
	})
	useEffect(() => {
		dispatch(getSkillsAction())
	}, [])
	
	return (
		<>

			<FusePageSimple
				header={
					<div className="flex flex-1 items-center justify-between p-24">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">view_list</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography variant="h6" className="mx-12 hidden sm:flex">
									Count Management
								</Typography>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					getSkillsStatus ? (
						<FuseSplashScreen />
					) : (
							<div className="p-24 max-w-xl">
								{softSkills && softSkills?.counts_managements ? (
									<Table counts_managements={softSkills?.counts_managements}/>
								) : <EmptyScreen />}
							</div>
						)
				}
			/>
		</>
	);
}
export default CountManagemets
