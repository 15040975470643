import authRoles from '../../auth/authRoles';
import EmailTemplates from './EmailTemplates';

const emailTemplateConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			exact: true,
			path: '/optir/dashboard/email-templates',
			component: EmailTemplates
		}
	]
};

export default emailTemplateConfig;