import { CHANGE_STATE } from 'app/store/actions/fuse/videos-action';

const initialState = {
    videos: {},
};

const videosReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_STATE: {
            return {
                videos: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default videosReducer;