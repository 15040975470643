import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import * as userActions from 'app/auth/store/actions';
import * as Actions from 'app/store/actions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import jwtService from 'app/services/jwtService';
import store from 'app/store';
import { showMessage, setNavigation } from 'app/store/actions/fuse';

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		this.authCheck();
	}

	authCheck = () => {
		let getAccess = localStorage.getItem('adminAcessToken');
		if (getAccess) {
			jwtService
				.getCurrentLoginStatus(getAccess)
				.then(res => {
					if (res.data.status === 200) {
						const navigation = this.props.navigation[0].children;
						if (res?.data?.permissions === 'All' && res.data?.superAdmin) {
							this.props.setNavigation(navigation);
						} else {
							const navigationKeys = res?.data?.permissions;
							const filteredNav = navigation?.filter(item => {
								return navigationKeys.some(page => {
									return item?.id === page?.pageId;
								});
							});
							const newNavigation = [
								{
									id: 'applications',
									title: 'Applications',
									translate: 'APPLICATIONS',
									type: 'group',
									icon: 'apps',
									children: filteredNav
								}
							];
							this.props.setNavigation(newNavigation);
							store.dispatch({
								type: 'ADMIN_PERMISSION',
								payload: res.data.permissions
							});
						}
						store.dispatch({
							type: 'CURRENT_USER_STATUS',
							payload: res.data.name
						});
						this.setState({ waitAuthCheck: false });
					} else {
						this.setState({ waitAuthCheck: false });
					}
				})
				.catch(error => {
					this.props.showMessage({ message: error.message });
					this.setState({ waitAuthCheck: false });
				});
		} else {
			this.setState({ waitAuthCheck: false });
		}
	};

	render() {
		console.log('<==========came here=========>');
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}
const mapStateToProps = ({ fuse }) => {
	return {
		navigation: fuse.navigation
	};
};
export default connect(mapStateToProps, { showMessage, setNavigation })(Auth);
