import React, { useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, Paper, TextField, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { styles } from './styles';

export default function VideoItem(props) {
	const [editState, setEditState] = React.useState(false);
	const [deleteState, setDeleteState] = React.useState(false);
	const [state, setState] = React.useState(props.video);

	const handleCheckChange = name => event => {
		setState({ ...state, [name]: event.target.checked });
	};

	useEffect(() => {
		if (props.video) {
			setState(props.video);
		}
	}, [props.video]);

	return (
		<Paper
			component="form"
			style={{
				padding: '20px',
				height: '280px'
			}}
			{...props}
		>
			<div style={styles.displayRow}>
				<Typography variant="h6">{state.pageName}</Typography>
				{!editState && !deleteState ? (
					<div>
						<Button color="primary" variant="contained" onClick={() => setEditState(true)}>
							Edit
						</Button>
						<Button
							color="primary"
							style={{ marginLeft: 5, backgroundColor: 'red' }}
							variant="contained"
							onClick={() => setDeleteState(true)}
						>
							Delete
						</Button>
					</div>
				) : deleteState ? (
					<div>
						<Button
							variant="contained"
							color="primary"
							style={{ marginRight: '10px', backgroundColor: 'red' }}
							onClick={() => {
								setDeleteState(false);
								props.onDelete(state?.key);
							}}
						>
							Yes
						</Button>
						<Button variant="contained" color="primary" onClick={() => setDeleteState(false)}>
							Cancel
						</Button>
					</div>
				) : (
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setEditState(false);
								setState(props.video);
							}}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							style={{ marginLeft: '10px' }}
							onClick={() => {
								props.onUpdate(state);
								setEditState(false);
							}}
						>
							Update
						</Button>
					</div>
				)}
			</div>
			{!editState && !deleteState ? (
				<div
					style={{
						paddingTop: '20px'
					}}
				>
					<ReactPlayer
						url={`https://player.vimeo.com/video/${state.videoID}`}
						allowfullscreen
						width="100%"
						height="100%"
						config={{
							playerOptions: {
								autoplay: false,
								loop: state.loop,
								autopause: true,
								muted: state.muted,
								controls: state.showControls
							},
							vimeo: {
								title: 'Resumas'
							}
						}}
					/>
				</div>
			) : deleteState ? (
				<div
					style={{
						marginTop: '10px',
						marginBottom: '20px',
						fontWeight: 'bold'
					}}
				>
					Are you sure about deleting this video
				</div>
			) : (
				<>
					<div
						style={{
							marginTop: '10px',
							marginBottom: '20px'
						}}
					>
						<TextField
							fullWidth
							color="primary"
							label="Video ID"
							placeholder="e.g. 630533349"
							required
							value={state.videoID}
							onChange={e => {
								setState({ ...state, videoID: e.target.value });
							}}
						/>
					</div>
					<div
						style={{
							marginTop: '10px',
							marginBottom: '20px'
						}}
					>
						<TextField
							fullWidth
							color="primary"
							label="Video Key"
							placeholder="e.g. Onboarding"
							required
							value={state.key}
							onChange={e => {
								setState({ ...state, key: e.target.value });
							}}
						/>
					</div>
					<div style={styles.displayRow}>
						<FormControlLabel
							control={
								<Checkbox
									name="showControls"
									color="primary"
									checked={state.showControls}
									onChange={handleCheckChange('showControls')}
								/>
							}
							label="Show Controls"
						/>
						<FormControlLabel
							style={{
								width: '120px'
							}}
							control={
								<Checkbox
									label="autoPlay"
									color="primary"
									checked={state.autoPlay}
									onChange={handleCheckChange('autoPlay')}
								/>
							}
							label="Auto Play"
						/>
					</div>
					<div style={styles.displayRow}>
						<FormControlLabel
							control={
								<Checkbox
									name="muted"
									color="primary"
									checked={state.muted}
									onChange={handleCheckChange('muted')}
								/>
							}
							label="Mute"
						/>
						<FormControlLabel
							style={{
								width: '120px'
							}}
							control={
								<Checkbox
									name="loop"
									color="primary"
									checked={state.loop}
									onChange={handleCheckChange('loop')}
								/>
							}
							label="Loop"
						/>
					</div>
				</>
			)}
		</Paper>
	);
}
