import FusePageSimple from '@fuse/core/FusePageSimple';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import './css.css';
import {
	getCoverAction,
	saveCoverLetterAction,
	updateCoverLetterAction,
	deleteCoverLetterAction
} from 'app/store/actions/fuse/subscription.action';

import Table from './usersTable';
import FuseSplashScreen from '@fuse/core/fuseSplash2';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import EmptyScreen from './empty';
import { useDispatch, useSelector } from 'react-redux';
const data = [0];
function CountManagemets(props) {
	const dispatch = useDispatch();
	const { refundStatus, covers } = useSelector(store => {
		return {
			covers: store.fuse.subscriptionReducer.covers,
			refundStatus: store.fuse.subscriptionReducer.refundStatus
		};
	});
	console.log('covers====>', covers);
	useEffect(() => {
		dispatch(getCoverAction());
	}, []);
	function GetCoverLetters() {}
	function AddCoverLetter(data) {
		dispatch(saveCoverLetterAction(data, () => dispatch(getCoverAction())));
	}

	function UpdateCoverLetter(data) {
		dispatch(updateCoverLetterAction(data, () => dispatch(getCoverAction())));
	}

	function DeleteCoverLatter(data) {
		
		dispatch(deleteCoverLetterAction(data, () => dispatch(getCoverAction())));
	}

	return (
		<>
			<FusePageSimple
				header={
					<div className="flex flex-1 items-center justify-between p-24">
						<div className="flex items-center styleButton">
							<FuseAnimate animation="transition.expandIn" delay={300}>
								<Icon className="text-32">note</Icon>
							</FuseAnimate>
							<FuseAnimate animation="transition.slideLeftIn" delay={300}>
								<Typography variant="h6" className="mx-12 hidden sm:flex">
									Cover Management
								</Typography>
							</FuseAnimate>
						</div>
					</div>
				}
				content={
					refundStatus ? (
						<FuseSplashScreen />
					) : (
						<div className="p-24">
							{covers?.length ? (
								<Table
									fileParser={GetCoverLetters}
									GetCoverLetters={GetCoverLetters}
									AddCoverLetter={AddCoverLetter}
									UpdateCoverLetter={UpdateCoverLetter}
									DeleteCoverLatter={DeleteCoverLatter}
									data={covers || []}
								/>
							) : (
								<EmptyScreen />
							)}
						</div>
					)
				}
			/>
		</>
	);
}
export default CountManagemets;
