import React from 'react'

export default () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
                src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
                style={{ width: '40%' }}
            />
        </div>
    )
}