import {
	DELETE_VERSION_DATA,
	CHANGE_VERSION_STATE,
	CLEAR_VERSION_DATA,
	DELETE_VERSION_LOADER,
	GET_VERSIONS,
	dummyData
} from 'app/store/actions/fuse/version.action';

const initialState = {
	deleteVersionData: {
		isDeleteState: false,
		deviceName: '',
		version: '',
		_id: ''
	},
	versions: {
		web: [],
		mobile: []
	},
	deleteLoader: false
};

const versionReducer = (state = initialState, action) => {
	switch (action.type) {
		case DELETE_VERSION_DATA:
			return {
				...state,
				deleteVersionData: action?.payload
			};
		case GET_VERSIONS:
			return {
				...state,
				versions: action?.payload
			};
		case CHANGE_VERSION_STATE:
			return {
				...state,
				versions: {
					...state.versions,
					[action?.payload?.name]: action?.payload?.versionList
				}
			};
		case CLEAR_VERSION_DATA:
			return {
				...state,
				deleteVersionData: {
					isDeleteState: false,
					deviceName: '',
					version: '',
					_id: '',
					isCurrent: false
				}
			};
		case DELETE_VERSION_LOADER:
			return {
				...state,
				deleteLoader: action?.payload
			};

		default:
			return state;
	}
};

export default versionReducer;
