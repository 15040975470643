import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { getUsersAction } from 'app/store/actions/fuse/usersActon';
import { connect } from 'react-redux';

function Widget1(props) {
	const wcurrentRange = 'DT';
	let ranges = {
		DT: 'Today',
		all: 'All'
	};
	let count = {
		DT: props.perDayUsers,
		all: props.totalLenth
	};
	const [currentRange, setCurrentRange] = useState(wcurrentRange);

	function handleChangeRange(ev) {
		setCurrentRange(ev.target.value);
	}

	React.useEffect(() => {
		props.getUsersAction(1, 10);
	}, []);

	return (
		<Paper className="w-full rounded-8 shadow-none border-1">
			<div className="flex items-center justify-between px-4 pt-4">
				<Select
					className="px-12"
					native
					value={currentRange}
					onChange={handleChangeRange}
					inputProps={{
						name: 'currentRange'
					}}
					disableUnderline
				>
					{Object.entries(ranges).map(([key, n]) => {
						return (
							<option key={key} value={key}>
								{n}
							</option>
						);
					})}
				</Select>
			</div>
			<div className="text-center pt-12 pb-28">
				<Typography className="text-72 leading-none text-blue">
					{count[currentRange] || 0}
				</Typography>
				<Typography className="text-16" color="textSecondary">
					Users
				</Typography>
			</div>
		</Paper>
	);
}

const mapStateToProps = store => {
	return {
		totalLenth: store.fuse.usersReducer.totalUsersLength,
		perDayUsers: store.fuse.usersReducer.perDayUsers
	};
};

export default connect(mapStateToProps, { getUsersAction })(React.memo(Widget1));
