/* eslint-disable jsx-a11y/label-has-associated-control */
import FuseAnimate from '@fuse/core/FuseAnimate';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, FormControl, FormControlLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { useTheme } from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import { AddAdminAction, getAdminAction } from 'app/store/actions/fuse/usersActon';
import toastr from 'toastr';
import FuseSplashScreen from '@fuse/core/fuseSplash2';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: '90%'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	button: {
		marginTop: 20
	},
	creditsField: {
		marginTop: 20,
		width: '100%'
	},
	mainDiv: {
		width: '100%',
		padding: '10px 25px 0px 25px'
	}
}));

export const pages = [
	{
		id: 0,
		pageName: 'Dashboard',
		pageId: 'dashboard',
		endpoint: '/',
		permission: false
	},
	{
		id: 1,
		pageName: 'User Management',
		pageId: 'users-management',
		endpoint: '/get-users',
		permission: false
	},
	{
		id: 2,
		pageName: 'Subscription Management',
		pageId: 'subscription-management',
		endpoint: '/get-subscription',
		permission: false
	},
	{
		id: 3,
		pageName: 'Refund Management',
		pageId: 'refund-management',
		endpoint: '/get-all-refunds',
		permission: false
	},
	{
		id: 4,
		pageName: 'Credentials Management',
		pageId: 'credentials-management',
		endpoint: '/get-credentials',
		permission: false
	},
	{
		id: 5,
		pageName: 'Sovren Management',
		pageId: 'sovren-management',
		endpoint: '/get-soft-skills',
		permission: false
	},
	{
		id: 6,
		pageName: 'Coupon Management',
		pageId: 'coupon-management',
		endpoint: '/get-coupon',
		permission: false
	},
	{
		id: 7,
		pageName: 'Cover Management',
		pageId: 'cover-management',
		endpoint: '/get-all-covers',
		permission: false
	},
	{
		id: 8,
		pageName: 'Count Management',
		pageId: 'count-management',
		endpoint: '/get-soft-skills',
		permission: false
	},
	{
		id: 9,
		pageName: 'Words To Avoid',
		pageId: 'words-to-avoid',
		endpoint: '/get-avoid-words',
		permission: false
	},
	{
		id: 10,
		pageName: 'Default Files',
		pageId: 'default-files',
		endpoint: '/get-resume-doc',
		permission: false
	},
	{
		id: 11,
		pageName: 'Video Links',
		pageId: 'videos-links',
		endpoint: '/get-video',
		permission: false
	},
	{
		id: 12,
		pageName: 'Top Soft Skills',
		pageId: 'top-soft-skills',
		endpoint: '/get-soft-skills',
		permission: false
	},
	{
		id: 13,
		pageName: 'Email Templates',
		pageId: 'email-templates',
		endpoint: '/get-email-templates',
		permission: false
	},
	{
		id: 14,
		pageName: 'Admin Management',
		pageId: 'admin-management',
		endpoint: '/getAdmin',
		permission: false
	},
	{
		id: 15,
		pageName: 'Temp Email Management',
		pageId: 'temp-email-management',
		endpoint: '/get-temp-mail',
		permission: false
	},
	{
		id: 16,
		pageName: 'Blocked Users',
		pageId: 'blocked-users',
		endpoint: '/get-block-users',
		permission: false
	},
	{
		id: 17,
		pageName: 'Json Uploader',
		pageId: 'json-uploader',
		endpoint: '/get-temp-mail',
		permission: false
	},
	{
		id: 18,
		pageName: 'Notification Handler',
		pageId: 'notification-handler',
		endpoint: '/getNotification',
		permission: false
	},
	{
		id: 19,
		pageName: 'Version Management',
		pageId: 'version-management',
		endpoint: '/get-versions',
		permission: false
	}
];

function UsersHeader(props) {
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const classes = useStyles();
	const [role, setRole] = React.useState('');
	const [email, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [name, setName] = useState('');
	// const [permissions, setPermissions] = useState([]);

	const [checkStatus, setCheckStatus] = useState(pages);
	const [refData, setRefData] = useState([]);
	const allAdmins = useSelector(store => store?.fuse?.usersReducer?.adminData);
	const ctaCheckHandleState = page => {
		const newState = checkStatus?.map(item => {
			if (item?.id === page?.id) {
				return {
					id: item?.id,
					pageName: item?.pageName,
					permission: !item?.permission,
					pageId: item?.pageId,
					endpoint: item?.endpoint
				};
			}

			return {
				id: item?.id,
				pageName: item?.pageName,
				permission: item?.permission,
				pageId: item?.pageId,
				endpoint: item?.endpoint
			};
		});
		setCheckStatus(newState);
		if (checkStatus[page?.id]?.id === page?.id && !checkStatus[page?.id]?.permission) {
			setRefData([...refData, newState[checkStatus[page?.id]?.id]]);
			console.log('push is working');
		} else if (checkStatus[page?.id]?.id === page?.id && checkStatus[page?.id]?.permission) {
			const newStudents = refData?.filter((student, i) => {
				if (student?.id !== page?.id) {
					return student;
				}
			});
			setRefData(newStudents);
			console.log('pop is working');
		}
	};
	console.log('ref', refData);
	const handleChange = event => {
		setRole(event.target.value);
		console.log('status', event.target.value);
	};
	async function ctaAddHandler() {
		const found = allAdmins?.find(element => element?.email === email);
		console.log(found, 'found');
		if (found) {
			toastr.error('Email already exist');
			return;
		}
		await props.AddAdminAction(email, password, role, refData, name);
		await props.getAdminAction();
		setTimeout(() => <FuseSplashScreen />, 2000);
		setOpen(false);
	}
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);
	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<FuseAnimate animation="transition.expandIn" delay={300}>
					<Icon className="text-32">group</Icon>
				</FuseAnimate>
				<FuseAnimate animation="transition.slideLeftIn" delay={300}>
					<Typography className="hidden sm:flex mx-0 sm:mx-12" variant="h6">
						Admin Management
					</Typography>
				</FuseAnimate>
			</div>

			<div className="flex flex-1 items-center justify-center px-12" style={{ gap: 10 }}>
				<ThemeProvider theme={mainTheme}>
					<FuseAnimate animation="transition.slideDownIn" delay={300}>
						<Paper className="flex items-center w-full max-w-512 px-8 py-4 rounded-8" elevation={1}>
							<Icon color="action">search</Icon>

							<Input
								placeholder="Search"
								className="flex flex-1 mx-8"
								onChange={e => props.sText(e.target.value)}
								disableUnderline
								fullWidth
								inputProps={{
									'aria-label': 'Search'
								}}
							/>
						</Paper>
					</FuseAnimate>
					<FuseAnimate style={{ marginLeft: 20 }}>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleClickOpen}
							style={{ marginLeft: 20 }}
						>
							Add Admin
						</Button>
					</FuseAnimate>
					<Dialog
						className="couponDialogBox"
						open={open}
						fullWidth
						onClose={handleClose}
						// aria-labelledby="form-dialog-title"
					>
						<DialogTitle id="form-dialog-title">
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<span>Add Admin</span>
							</div>
						</DialogTitle>
						<DialogContent>
							<div className={classes.mainDiv}>
								<InputLabel id="demo-simple-select-readonly-label" className={classes.creditsField}>
									Email
								</InputLabel>
								<TextField
									fullWidth
									type="email"
									value={email}
									onChange={e => setUserName(e.target.value)}
								/>
								<InputLabel id="demo-simple-select-readonly-label" className={classes.creditsField}>
									Name
								</InputLabel>
								<TextField fullWidth type="text" value={name} onChange={e => setName(e.target.value)} />
								<InputLabel id="demo-simple-select-readonly-label" className={classes.creditsField}>
									Password
								</InputLabel>
								<TextField
									fullWidth
									type="password"
									value={password}
									onChange={e => setPassword(e.target.value)}
								/>
								<InputLabel id="demo-simple-select-readonly-label" className={classes.creditsField}>
									Select Role
								</InputLabel>
								<Select
									labelId="demo-simple-select-readonly-label"
									id="demo-simple-select-readonly"
									value={role}
									onChange={handleChange}
									fullWidth
								>
									<MenuItem value="Admin">Admin</MenuItem>
									<MenuItem value="Super Admin">Super Admin</MenuItem>
								</Select>
								<InputLabel id="demo-simple-select-readonly-label" className={classes.creditsField}>
									Tabs Permission
								</InputLabel>
								{pages?.map(page => {
									return (
										<FormControlLabel
											control={
												<Checkbox
													// defaultChecked={checkStatus[page.id]}
													onChange={() => ctaCheckHandleState(page)}
												/>
											}
											label={page?.pageName}
										/>
									);
								})}
							</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Cancel
							</Button>
							<Button variant="contained" color="primary" onClick={ctaAddHandler} startIcon={<AddIcon />}>
								Add
							</Button>
						</DialogActions>
					</Dialog>
				</ThemeProvider>
			</div>
		</div>
	);
}
export default connect(null, { AddAdminAction, getAdminAction })(UsersHeader);
