import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getVideos, updateVideos } from 'app/store/actions/fuse/videos-action';
import Alert from '@material-ui/lab/Alert';
import toastr from 'toastr';

const sortingOrder = [
	'IntroVideo',
	'ScanPage',
	'ReviewResults',
	'FineTune',
	'CustomizeResume',
	'SaveAndApply',
	'FollowUpEmail',
	'ResumeBuilder',
	'Dashboard',
	'Employer',
	'HelpPopup',
	'JobFinder',
	'LandingPage',
	'LinkedIn',
	'Manage',
	'Onboarding'
];

export default function useVideos() {
	const dispatch = useDispatch();
	const videos = useSelector(state => state.fuse.videosReducer.videos);
	const [addVideoModal, setAddVideoModal] = useState(false);
	const [addVideoLoader, setAddVideoLoader] = useState(false);
	const [addVideoId, setAddVideoId] = useState('');
	const [addVideoKey, setAddVideoKey] = useState('');
	const [addVideoName, setAddVideoName] = useState('');

	const videoEntries = Object.entries(videos);
	const sortedEntries = videoEntries.sort((a, b) => {
		const indexA = sortingOrder.indexOf(a[0]);
		const indexB = sortingOrder.indexOf(b[0]);
		return indexA - indexB;
	});
	const sortedVideos = Object.fromEntries(sortedEntries);
	const toggleAddModal = () => {
		setAddVideoModal(!addVideoModal);
	};
	const addVideoCallBack = () => {
		setAddVideoLoader(false);
		toggleAddModal();
		setAddVideoId('');
		setAddVideoKey('');
		setAddVideoName('');
	};

	const checkIfPageNameExists = pageName => {
		for (const key in sortedVideos) {
			if (sortedVideos.hasOwnProperty(key)) {
				const item = sortedVideos[key];
				if (item.pageName.toLowerCase().trim() === pageName?.toLowerCase().trim()) {
					return true;
				}
			}
		}
		return false;
	};

	const checkIfVideoIDExists = videoID => {
		for (const key in sortedVideos) {
			if (sortedVideos.hasOwnProperty(key)) {
				const item = sortedVideos[key];
				if (item.videoID === videoID) {
					return true;
				}
			}
		}
		return false;
	};

	const checkKeyExist = itemKey => {
		for (const key in sortedVideos) {
			if (itemKey.toLowerCase() === key.toLowerCase()) {
				return true;
			}
		}
		return false;
	};
	const addVideosHandler = () => {
		const videosData = sortedVideos || {};
		let videoKey = addVideoKey;
		if (!addVideoId || !addVideoKey || !addVideoName) {
			toastr.error('All Fields are required');
			return;
		}
		if (addVideoKey.includes(' ')) {
			const keyArr = videoKey.split(' ');
			videoKey = keyArr?.map(key => key.charAt(0).toUpperCase() + key.slice(1))?.join('');
		}
		if (checkKeyExist(videoKey)) {
			toastr.error('Key already Exists!');
			return;
		}
		if (checkIfPageNameExists(addVideoName)) {
			toastr.error('Video Name already Exists!');
			return;
		}
		if (checkIfVideoIDExists(addVideoId)) {
			toastr.error('Video Id already Exists!');
			return;
		}
		if (addVideoKey.includes(' ')) {
			const keyArr = videoKey.split(' ');
			videoKey = keyArr?.map(key => key.charAt(0).toUpperCase() + key.slice(1))?.join('');
		}
		const newAddData = {
			autoPlay: true,
			key: videoKey,
			loop: false,
			muted: false,
			pageName: addVideoName,
			showControls: true,
			videoID: addVideoId
		};
		setAddVideoLoader(true);
		const newVideosData = { ...videosData, [videoKey]: newAddData };
		dispatch(updateVideos(newVideosData, addVideoCallBack));
	};

	const updateHandler = state => {
		let newData = Object.entries(sortedVideos).map(([key, value]) => {
			if (value._id === state._id) {
				return [state.key, state];
			}
			return [key, value];
		});
		newData = Object.fromEntries(newData);
		dispatch(updateVideos(newData));
	};

	const deleteVideoHandler = videoKey => {
		if (videoKey in sortedVideos) {
			delete sortedVideos[videoKey];
			dispatch(updateVideos(sortedVideos));
		}
	};

	const videoKeys = Object.keys(sortedVideos) ?? [];

	useEffect(() => {
		dispatch(getVideos());
	}, []);

	return {
		sortedVideos,
		videoKeys,
		updateHandler,
		addVideoModal,
		toggleAddModal,
		addVideoId,
		addVideoKey,
		addVideoName,
		setAddVideoName,
		setAddVideoKey,
		setAddVideoId,
		addVideosHandler,
		addVideoLoader,
		deleteVideoHandler
	};
}
