import { CURRENT_LOGIN_STATUS, LOGIN_REQUEST,LOGOUT_SUCCESS, LOGIN_SUCCESS, LOGIN_FAILURE, ADMIN_PERMISSION } from '../../actions/fuse/auth.actions';
const initialState = {
	role: [''],
	userStatus: false,
	loader: false,
	permission: [],
	name: ''
};

const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				role: ['admin'],
				userStatus: true
			};
		case LOGIN_FAILURE:
			return {
				...state,
				role: ['access failed'],
				userStatus: false,
				loader:false
			};
		case 'CURRENT_USER_STATUS':
			console.log('called');
			return {
				...state,
				role: ['admin'],
				userStatus: true,
				name: action.payload
			};
		case LOGOUT_SUCCESS:
			return {
				...state,
				role: [''],
				userStatus: false,
				loader:false
			};
		case LOGIN_REQUEST:
			return {
				...state,
				loader:true
			};
		
		

		default: {
			return { ...state };
		}
	}
};

export default loginReducer;
