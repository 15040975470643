import React, { useState, useRef, memo, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Input } from "@material-ui/core";
import toastr from "toastr";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { styles } from "./style";
import { BeatLoader } from "react-spinners";

import TagsInput from "react-tagsinput";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import { css } from "@emotion/core";
// import "./style.css";
import FroalaEditorComponent from "react-froala-wysiwyg";

let convertedNotification = "";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CustomEditor = memo(({ checkWord, defaultText = "" }) => {
  const editor = useRef(null);
  const [content, setContent] = useState(true);
  useEffect(() => {
    // if(editor.current){
    //   onChange(defaultText)
    // }
    // else{
    setTimeout(() => {
      setContent(false);
    }, 1000);
    // }
    // return function remove() {
    //   convertedNotification = "";
    // };
  }, []);
  // const config = {
  //   removeButtons: ["image", "file"],
  //   enter: "br",

  //   uploader: { insertImageAsBase64URI: false },
  //   readonly: false, // all options from https://xdsoft.net/jodit/doc/
  // };

  const onChange = (newContent) => {
    // console.log("newContent=>",newContent)
    // editor.current.value = newContent;
    convertedNotification = newContent;
    console.log(convertedNotification);
    // checkWord(newContent);
  };
  const configFroalaEditor = {
    // htmlRemoveTags: ["base"],
    // events: {
    //   blur: checkWord,
    // },
    toolbarSticky: false,
    quickInsertTags: false,
    height: 300,
    toolbarButtons: {
      moreText: {
        buttons: ["bold", "italic", "underline", "fontFamily"],
      },
      moreParagraph: {
        buttons: [
          "formatUL",
          // "paragraphStyle",
          // "lineHeight",
          // "outdent",
          // "indent",
          // "quote",
        ],
      },
      moreRich: {
        buttons: ["insertLink"],
      },
      moreMisc: {
        buttons: [
          "undo",
          "redo",
          // "fullscreen",
          "print",
          // "getPDF",
          // "spellChecker",
          // "selectAll",
          // "html",
          // "help",
        ],
        align: "right",
        buttonsVisible: 3,
      },
    },
    charCounterCount: false,
    attribution: false,
    key:
      "CTD5xB1C2B2C1A7B5wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4A3C6E2F2F4G1C1==",
    // htmlRemoveTags: ["script", "style", "base"],
  };
  if (content) {
    return (
      <center>
        <BeatLoader
          className="loader"
          css={override}
          sizeUnit={"px"}
          size={10}
          color={"#D5641C"}
          loading={true}
        />
      </center>
    );
  }

  return (
    <center>
      <div>
        <FroalaEditorComponent
          config={configFroalaEditor}
          model={defaultText}
          onModelChange={onChange}
        />
      </div>
    </center>
  );
});

function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [tags, setTags] = React.useState([]);
  // const [convertedNotification, setconvertedNotification] = React.useState(
  //   EditorState.createEmpty()
  // );
  console.log(convertedNotification);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const onEditorStateChange = (convertedNotification) => {
  //   setconvertedNotification(convertedNotification);
  // };

  // React.useEffect(() => {
  //   let setEditorState;

  //   if (props.resumeDescription.trim() !== "") {
  //     const setprocessedHTML = DraftPasteProcessor.processHTML(
  //       props.resumeDescription
  //     );
  //     const setcontentState = ContentState.createFromBlockArray(
  //       setprocessedHTML
  //     );
  //     setEditorState = EditorState.createWithContent(setcontentState);
  //     setEditorState = EditorState.moveFocusToEnd(setEditorState);
  //   } else {
  //     setEditorState = EditorState.createEmpty();
  //   }

  //   // setconvertedNotification(setEditorState);
  // }, [props.resumeDescription]);

  const handleFile = (e) => {
    if (["pdf"].includes(e.target.files[0].type.split("/")[1])) {
      props.fileParser(e.target.files[0]);
    } else if (["docx"].includes(e.target.files[0].name.split(".")[1])) {
      props.fileParser(e.target.files[0]);
    } else {
      toastr.error("please upload pdf and docx only");
    }
  };

  const handleSubmit = () => {
    if (title === "") {
      toastr.error("please add title!");
    } else if (convertedNotification === EditorState.createEmpty()) {
      toastr.error("please add description!");
    } else {
      // let convertDesc = draftToHtml(
      //   convertToRaw(convertedNotification.getCurrentContent())
      // );

      let data = {
        title: title,
        description: convertedNotification,
        tags: tags,
      };
      props.AddCoverLetter(data);
      setTitle("");
      // setconvertedNotification(EditorState.createEmpty());
      handleClose();
      props.getCoverData(1);
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen} size="smaill" style={styles.btnModel}>
        Add
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div style={styles.dialogTitle}>
            <span>Add New Cover Letter</span>
            {/* <span class="image-upload">
              <label for="file-input" style={styles.imgUploadModel}>
                Upload Cover Letter
              </label>

              <input id="file-input" type="file" onChange={handleFile} />
            </span> */}
          </div>
        </DialogTitle>
        <DialogContent>
          <Input
            value={title}
            placeholder="Add Title"
            onChange={(e) => setTitle(e.target.value)}
          />
          {/* <InputGroup style={{ ...styles.inputGroup, marginTop: 10 }}>
            <InputGroupAddon addonType="prepend"> */}
              {/* <span className="input-group-text">Tags</span> */}
            {/* </InputGroupAddon> */}

            <TagsInput
              value={tags}
              onChange={(tags) => {
                setTags(tags);
              }}
              addOnBlur
              inputProps={{
                id: "title",
                placeholder: "Add Tags",
              }}
            />
          {/* </InputGroup> */}
          <div style={styles.inputModel}>
            {/* <Col lg="12" style={{ padding: 0 }}> */}
              <CustomEditor
                defaultText={convertedNotification}
              />
            {/* </Col> */}
            {/* <Editor
              editorState={convertedNotification}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            /> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Letter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
