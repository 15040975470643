import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Pagination } from '@material-ui/lab';
import FuseSplashScreen from '@fuse/core/fuseSplash2';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteModal from '@fuse/core/deleteModal/DeleteModal';
import UserManagementService from 'app/services/userManagementService';
import UserTableHead from './userTableHead';
import { getUsersAction } from '../../store/actions/fuse/usersActon';

const activeStatus = {
	color: 'white',
	backgroundColor: '#4caf50',
	padding: '.4rem',
	borderRadius: '.4rem',
	textAlign: 'center'
};

const blockStatus = {
	color: 'white',
	backgroundColor: '#d32f2f',
	padding: '.4rem',
	borderRadius: '.4rem',
	textAlign: 'center'
};

function UsersTable(props) {
	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	// const [page, setPage] = useState(1);
	const [defaultPage, setDefaultPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [modal, setModal] = useState(false);
	const [deleteUser, setDeletedUser] = useState(null);
	const [order, setOrder] = useState({
		direction: 'asc',
		id: null
	});
	const [deleteUserLoader, setDeleteUserLoader] = useState(false);
	useEffect(() => {
		props.getUsersAction(1, rowsPerPage);
	}, []);

	useEffect(() => {
		setData(props.getUsers);
	}, [props.getUsers]);

	const handleModal = () => setModal(!modal);
	const deletedUser = async () => {
		try {
			const deleteUserData = {
				email: deleteUser,
				feedback: ''
			};
			setDeleteUserLoader(true);
			await UserManagementService.deleteUser(deleteUserData);
			props.getUsersAction(1, rowsPerPage);
		} catch (error) {
			console.log('error:', error);
		} finally {
			setDeleteUserLoader(false);
			handleModal();
		}
	};
	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		console.log(order.id);

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	function handleClick(item, index) {
		props.history.push(`/optir/dashboard/users/detail/u${index + 1}/${item.id}`, item);
	}

	function handleChangePage(event, value) {
		props.getUsersAction(value, rowsPerPage);
		setDefaultPage(value);
	}
	const column = ['firstName', 'email', 'lastName', 'phone', 'city', 'country'];
	var filteredData = data;
	let searchStringForData = props.sendSText.trim().toLowerCase();
	if (searchStringForData.length > 0) {
		filteredData = filteredData.filter(item => {
			// return item.firstName.toLowerCase().match(searchStringForData);
			return Object.keys(item).some(key => {
				return column.includes(key)
					? item[key]
							.toString()
							.toLowerCase()
							.includes(searchStringForData)
					: console.log('key', key);
			});
		});
	}

	if (props.getUsersStatus) {
		return <FuseSplashScreen />;
	}
	return props.getUsers && props.getUsers.length ? (
		<>
			<div className="w-full flex flex-col">
				<FuseScrollbars className="flex-grow overflow-x-auto">
					<Table className="min-w-xl" aria-labelledby="tableTitle">
						<UserTableHead
							numSelected={selected.length}
							order={order}
							onRequestSort={handleRequestSort}
							rowCount={filteredData.length}
						/>

						<TableBody>
							{_.orderBy(
								filteredData,
								[
									o => {
										switch (order.id) {
											case 'id': {
												return parseInt(o.id, 10);
											}
											case 'firstName': {
												return o.firstName;
											}
											case 'lastName': {
												return o.lastName;
											}
											case 'email': {
												return o.email;
											}
											case 'phone': {
												return o.phone;
											}
											case 'gender': {
												return o.gender;
											}
											case 'birthDate': {
												return o.birthDate;
											}
											case 'country': {
												return o.country;
											}
											case 'city': {
												return o.city;
											}
											case 'education': {
												return o.education;
											}
											case 'registeredAt': {
												return o.registeredAt;
											}
											case 'status': {
												return o.status;
											}
											default: {
												return '';
											}
										}
									}
								],
								[order.direction]
							).map((items, index) => {
								console.log('items:', items);
								return (
									<TableRow
										onClick={event => handleClick(items, index)}
										hover
										key={index}
										className="h-64 cursor-pointer"
									>
										<TableCell component="th" scope="row">
											{items.firstName}
										</TableCell>
										<TableCell component="th" scope="row">
											{items.lastName}
										</TableCell>
										<TableCell component="th" scope="row">
											{items.email}
										</TableCell>
										<TableCell component="th" scope="row">
											{items.phone}
										</TableCell>
										{/* <TableCell component="th" scope="row">
										{items.gender}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.birthDate}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.country}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.city}
									</TableCell>
									<TableCell component="th" scope="row">
										{items.education}
									</TableCell> */}
										<TableCell component="th" scope="row">
											{items.registeredAt}
										</TableCell>
										<TableCell component="th" scope="row">
											<div style={items.status === 'active' ? activeStatus : blockStatus}>
												{items.status}
											</div>
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											style={{ display: 'flex', justifyContent: 'flex-end' }}
										>
											<IconButton
												onClick={e => {
													e.stopPropagation();
													setDeletedUser(items?.email);
													handleModal();
												}}
												style={{ color: items.status === 'deleted' ? 'gray' : 'red' }}
												disabled={items.status === 'deleted'}
											>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>

				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
					<Pagination
						defaultPage={defaultPage}
						count={Math.ceil(props.totalLenth / rowsPerPage)}
						variant="outlined"
						color="secondary"
						onChange={handleChangePage}
					/>
				</div>
			</div>
			<DeleteModal
				deleteHandler={deletedUser}
				handleModal={handleModal}
				loader={deleteUserLoader}
				modal={modal}
				subtitle="Profile and data will be permanently delete."
				title="Are you Sure?"
				textAlign="center"
			/>
		</>
	) : (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
			<img
				src="https://image.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
				alt="user_image"
				style={{ width: '40%' }}
			/>
		</div>
	);
}

const mapStateToProps = store => {
	return {
		getUsers: store.fuse.usersReducer.data,
		totalLenth: store.fuse.usersReducer.totalUsersLength,
		getUsersStatus: store.fuse.usersReducer.getUsersStatus
	};
};

export default connect(mapStateToProps, { getUsersAction })(withRouter(UsersTable));
