import { Box } from '@material-ui/core';
import React from 'react';
import VersionEntry from './VersionEntry';

const VersionItem = ({ data, name }) => {
	return (
		<>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					gap: 20,
					backgroundColor: 'transparent'
				}}
			>
				{data?.length === 0 ? (
					<p className="p-4 mt-2">No version found</p>
				) : (
					data?.toReversed().map((item, index) => <VersionEntry key={index} name={name} item={item} />)
				)}
			</Box>
		</>
	);
};

export default VersionItem;
