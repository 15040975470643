import Axios from 'axios';
import toastr from 'toastr';
import { url } from '../../../endpoint';

export const IS_DELETE_STATE = 'IS_DELETE_STATE';
export const DELETE_VERSION_DATA = 'DELETE_VERSION_DATA';
export const CLEAR_VERSION_DATA = 'CLEAR_VERSION_DATA';
export const CHANGE_VERSION_STATE = 'CHANGE_VERSION_STATE';
export const DELETE_VERSION_LOADER = 'DELETE_VERSION_LOADER';
export const UPDATE_IS_CURRENT_VERSION = 'UPDATE_IS_CURRENT_VERSION';
export const GET_VERSIONS = 'GET_VERSIONS';
export const dummyData = {
	web: [
		{
			_id: 'web1',
			version: '1.0.0',
			updatedAt: new Date(),
			createdAt: new Date(),
			isCurrent: true
		},
		{
			_id: 'web2',
			version: '1.1.0',
			updatedAt: new Date(),
			createdAt: new Date(),
			isCurrent: false
		}
		// Add up to 10 versionList items for web...
	],

	android: [
		{
			_id: 'android1',
			version: '2.0.0',
			updatedAt: new Date(),
			createdAt: new Date(),
			isCurrent: true
		},
		{
			_id: 'android2',
			version: '2.1.0',
			updatedAt: new Date(),
			createdAt: new Date(),
			isCurrent: false
		}
		// Add up to 10 versionList items for android...
	],

	ios: [
		{
			_id: 'ios1',
			version: '3.0.0',
			updatedAt: new Date(),
			createdAt: new Date(),
			isCurrent: true,
			description: 'This is the first version of iOS'
		},
		{
			_id: 'ios2',
			version: '3.1.0',
			updatedAt: new Date(),
			createdAt: new Date(),
			isCurrent: false,
			description: 'This is the second version of iOS'
		}
		// Add up to 10 versionList items for iOS...
	]
};

export const getVersions = cb => async dispatch => {
	try {
		const res = await Axios.get(`${url}/admin/get-versions`);
		if (res?.status === 200) {
			dispatch({
				type: GET_VERSIONS,
				payload: res?.data
			});
		}
	} catch (error) {
		console.log(error);
	} finally {
		if (cb) {
			cb();
		}
	}
};

export const updateVersions = (name, data, cb) => async (dispatch, getState) => {
	try {
		const res = await Axios.post(`${url}/admin/update-version`, {
			[name]: {
				versionId: data?._id,
				version: data?.version
			}
		});
		if (res?.data?.status === 200) {
			const versions = getState().fuse.versionReducer?.versions;

			const result = versions[name]?.map(item => {
				if (item?._id === data?._id) {
					return data;
				}
				return item;
			});
			dispatch({
				type: CHANGE_VERSION_STATE,
				payload: {
					name,
					versionList: result
				}
			});
			toastr.success(res?.data?.message);
		}
	} catch (error) {
		console.log(error);
	} finally {
		if (cb) {
			cb();
		}
	}
};

export const addVersions = (name, data, cb) => async (dispatch, getState) => {
	try {
		const res = await Axios.post(`${url}/admin/add-new-version`, {
			[name]: data
		});

		if (res.data.status === 200) {
			const versions = getState().fuse.versionReducer?.versions;
			const result = versions[name]?.map(item => {
				return { ...item, isCurrent: false };
			});
			result.push(res?.data?.data);
			dispatch({
				type: CHANGE_VERSION_STATE,
				payload: {
					name,
					versionList: result
				}
			});
			toastr.success(res?.data?.message);
			if (cb) {
				cb();
			}
		}
	} catch (error) {
		console.log(error);
	} finally {
		if (cb) {
			cb();
		}
	}
};

export const deleteVersions = cb => async (dispatch, getState) => {
	try {
		const data = getState().fuse.versionReducer?.deleteVersionData;
		const res = await Axios.post(`${url}/admin/delete-version`, {
			[data?.name]: {
				versionId: data?._id
			}
		});

		if (res.data.status === 200) {
			const versions = getState().fuse.versionReducer?.versions;
			const result = versions[data?.name]?.filter(item => item?._id !== data?._id);
			dispatch({
				type: CHANGE_VERSION_STATE,
				payload: {
					name: data?.name,
					versionList: result
				}
			});
			toastr.success(res?.data?.message);
			dispatch({
				type: CLEAR_VERSION_DATA
			});
		}
	} catch (error) {
		console.log(error);
	} finally {
		dispatch({
			type: CLEAR_VERSION_DATA
		});
		if (cb) {
			cb();
		}
	}
};

// export const updateIsCurrent = (data, cb) => async (dispatch, getState) => {
// 	try {
// 		// const res = await Axios.post(`${url}/admin/db/update-is-current`, {
// 		// 	data
// 		// });
// 		let updatedData;
// 		const versions = getState().fuse.versionReducer?.versions;
// 		const result = versions[name]?.reduce((acc, item) => {
// 			if (item?._id === data?._id) {
// 				updatedData = { ...item, isCurrent: true };
// 				acc.unshift(updatedData);
// 			} else {
// 				acc.push({ ...item, isCurrent: false });
// 			}
// 			return acc;
// 		}, []);
// 		if (true) {
// 			dispatch({
// 				type: CHANGE_VERSION_STATE,
// 				payload: {
// 					name: name,
// 					versionList: result
// 				}
// 			});
// 			toastr.success(`Version ${updatedData?.version} is now current ${name}'s version`);
// 		}
// 	} catch (error) {
// 		console.log(error);
// 	} finally {
// 		if (cb) {
// 			cb();
// 		}
// 	}
// };
